import { fetchLicenseeContractNumberService, fetchLicenseeDetailsService, fetchLicenseeNamesService } from '@/api/requests/baLicenseeRequests';
import {
    fetchUserLicenseesListByLicenseeIdService, fetchDrmLicenseesListService, fetchDrmArticlesDetailsService, drmContractSyncService, drmRefreshViewsService,
    fetchDrmLicenseeContractDetailsService, fetchDrmChannlesDetailsService, fetchDrmLicenseesContractListService, fetchDrmTerritoiesDetailsService,
    fetchDrmLicenseeDetailsService, fetchSubmissionsContractualSamplesService, drmEnforceSampleService
} from '@/api/requests/baDrmServicesRequests';

export default {
    namespaced: true,

    state () {
        return {
            licenseeNamesList: null,
            licensees: null,
            licenseeContractNumber: null,
            licenseeDrmContractNumber: null,
            licenseeDrmDetails: null,
            licenseeDetails: null,
            licenseeContactList: null,
            territoryContactList: null,
            channelContractList: null,
            articleContractList: null,
            licenseeContractDetailsList: null,
            submissionContractualSamplesList: null
        };
    },

    getters: {
        getLicenseeNamesList (state) {
            return state.licenseeNamesList;
        },
        getLicenseeContractNumber (state) {
            return state.licenseeContractNumber;
        },
        getDrmLicenseeContractNumber (state) {
            return state.licenseeDrmContractNumber;
        },
        getLicensees (state) {
            return state.licensees;
        },
        getLicenseeDetails (state) {
            return state.licenseeDetails;
        },
        getDrmLicenseeDetails (state) {
            return state.licenseeDrmDetails;
        },
        getLicenseeContactList (state) {
            return state.licenseeContactList;
        },
        getTerritoryContractList (state) {
            return state.territoryContactList;
        },
        getChannelContractList (state) {
            return state.channelContractList;
        },
        getArticleContractList (state) {
            return state.articleContractList;
        },
        getLicenseeContractDetailsList (state) {
            return state.licenseeContractDetailsList;
        },
        getDrmSubmissionContractualSamplesList (state) {
            return state.submissionContractualSamplesList;
        }
    },
    mutations: {
        setLicenseeNamesList (state, newLicenseeNamesList) {
            state.licenseeNamesList = newLicenseeNamesList;
        },
        setLicenseeContractNumber (state, licenseeContractNumber) {
            state.licenseeContractNumber = licenseeContractNumber;
        },
        setDrmLicenseeContractNumber (state, licenseeDrmContractNumber) {
            state.licenseeDrmContractNumber = licenseeDrmContractNumber;
        },
        setSubmissionContractualSamplesList (state, contractualSamplesList) {
            state.submissionContractualSamplesList = contractualSamplesList;
        },
        setLicensees (state, licensees) {
            state.licensees = licensees;
        },
        setLicenseeDetails (state, licenseeDetails) {
            state.licenseeDetails = licenseeDetails;
        },
        setDrmLicenseeDetails (state, licenseeDrmDetails) {
            state.licenseeDrmDetails = licenseeDrmDetails;
        },
        setLicenseeContactList (state, licenseeContactList) {
            state.licenseeContactList = licenseeContactList;
        },
        setDrmTerritoriesDetails (state, territoryContactList) {
            state.territoryContactList = territoryContactList;
        },
        setDrmChannelsDetails (state, channelContractList) {
            state.channelContractList = channelContractList;
        },
        setDrmArticlesDetails (state, articleContractList) {
            state.articleContractList = articleContractList;
        },
        setLicenseeContractDetails (state, licenseeContractDetailsList) {
            state.licenseeContractDetailsList = licenseeContractDetailsList;
        }
    },
    actions: {
        async fetchLicenseeNames ({ commit }, { params }) {
            const { data: { data } } = await fetchLicenseeNamesService(params);
            commit('setLicenseeNamesList', data);
        },
        async fetchLicenseeContractNumber ({ commit }, { params }) {
            const { data: { data } } = await fetchLicenseeContractNumberService(params);
            commit('setLicenseeContractNumber', data);
        },
        async fetchDrmLicenseesContractList ({ commit }, { id, params }) {
            const { data: { data } } = await fetchDrmLicenseesContractListService(id, params);
            commit('setDrmLicenseeContractNumber', data);
        },
        async fetchSubmissionsContractualSamples ({ commit }, { contractNumber }) {
            const { data: { data } } = await fetchSubmissionsContractualSamplesService(contractNumber);
            commit('setSubmissionContractualSamplesList', data);
        },
        async fetchDrmLicenseesList ({ commit }, { params }) {
            const { data: { data } } = await fetchDrmLicenseesListService(params);
            commit('setLicensees', data);
        },
        async fetchLicenseeDetails ({ commit }, { name }) {
            const { data: { data } } = await fetchLicenseeDetailsService(name);
            commit('setLicenseeDetails', data);
        },
        async fetchDrmLicenseeDetails ({ commit }, { id }) {
            const { data: { data } } = await fetchDrmLicenseeDetailsService(id);
            commit('setDrmLicenseeDetails', data);
        },
        async fetchLicenseeContactList ({ commit }, { licenseeId }) {
            const { data: { data } } = await fetchUserLicenseesListByLicenseeIdService(licenseeId);
            commit('setLicenseeContactList', data);
            return data;
        },
        async fetchDrmTerritoiesDetails ({ commit }, { params, id, onlyReturnValues }) {
            const { data: { data } } = await fetchDrmTerritoiesDetailsService(params, id);
            if (onlyReturnValues) return data;
            commit('setDrmTerritoriesDetails', data);
        },
        async fetchDrmChannelsDetails ({ commit }, { params, id, onlyReturnValues }) {
            const { data: { data } } = await fetchDrmChannlesDetailsService(params, id);
            if (onlyReturnValues) return data;
            commit('setDrmChannelsDetails', data);
        },
        async fetchDrmArticlesDetails ({ commit }, { params, id, onlyReturnValues }) {
            const { data: { data } } = await fetchDrmArticlesDetailsService(params, id);
            // console.log(`Fetched Articles : ${data.pageCount} .. ${data.totalCount}`)

            if (onlyReturnValues) return data;
            commit('setDrmArticlesDetails', data);
        },
        async fetchDrmLicenseeContractDetails ({ commit }, { id }) {
            const { data: { data } } = await fetchDrmLicenseeContractDetailsService(id);
            commit('setLicenseeContractDetails', data);
        },
        async drmContractSync ({ commit }, { bodyPayload }) {
            const { data: { data } } = await drmContractSyncService(bodyPayload);
            return data;
        },
        async drmEnforceSample ({ commit }, { bodyPayload }) {
            const { data: { data } } = await drmEnforceSampleService(bodyPayload);
            return data;
        },

        async drmRefreshViews ({ commit }) {
            const resp = await drmRefreshViewsService();
            return resp;
        },
        clearLicenseeContactList ({ commit }) {
            commit('setLicenseeContactList', null);
        },
        clearDrmLicenseeContractList ({ commit }) {
            commit('setDrmLicenseeContractNumber', null);
        }
    }
};
