import { computed } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { areOktaTokensValid } from '@/helpers/authCheck.js';

import * as ROUTES from '@/constants/routes.js';
import * as PERMISSIONS from '@/constants/permissions.js';
import * as BA_ROLES from '@/constants/ba-roles.js';

import store from '@/store/store';

const routes = [
    {
        path: '/auth',
        component: () => import('@/layouts/AuthLayout.vue'),
        redirect: ROUTES.LOGIN_PATH,
        children: [
            {
                path: `/${ROUTES.LOGIN_PATH}`,
                name: ROUTES.LOGIN_NAME,
                component: () => import('@/views/Login.vue'),
                meta: {
                    navigationGuards: ['AUTOREDIRECTHOME']
                }
            },
            {
                path: `/${ROUTES.LOGIN_REDIRECT_PATH}`,
                name: ROUTES.LOGIN_REDIRECT_NAME,
                component: () => import('@/views/LoginRedirect.vue')
            }
        ]
    },
    {
        path: '/',
        component: () => import('@/layouts/DefaultLayout.vue'),
        redirect: ROUTES.PORTAL_SELECTION_PATH,
        meta: {
            navigationGuards: ['AUTH']
        },
        children: [
            {
                path: ROUTES.STYLE_GUIDES_PATH,
                name: ROUTES.STYLE_GUIDES_NAME,
                component: () => import('@/views/StyleGuides.vue'),
                meta: {
                    navigationGuards: ['AUTH', 'SG']
                }
            },
            {
                path: ROUTES.BASKET_PATH,
                name: ROUTES.BASKET_NAME,
                component: () => import('@/views/Basket.vue'),
                meta: {
                    navigationGuards: ['AUTH', 'SG']
                }
            },
            {
                path: ROUTES.BRAND_ASSURANCE_PATH,
                name: ROUTES.BRAND_ASSURANCE_NAME,
                component: () => import('@/views/BrandAssurance.vue'),
                children: [
                    {
                        path: ROUTES.BA_INBOX_PATH,
                        name: ROUTES.BA_INBOX_NAME,
                        component: () => import('@/views/BAInbox.vue'),
                        meta: {
                            navigationGuards: ['AUTH', 'BA']
                        }
                    },
                    {
                        path: ROUTES.BA_ADVANCE_SEARCH_PATH,
                        name: ROUTES.BA_ADVANCE_SEARCH_NAME,
                        component: () => import('@/views/BAAdvanceSearch.vue'),
                        meta: {
                            navigationGuards: ['AUTH', 'BA']

                        }
                    },
                    {
                        path: ROUTES.BA_COLLABORATION_PATH,
                        name: ROUTES.BA_COLLABORATION_NAME,
                        component: () => import('@/views/BACollaboration.vue'),
                        meta: {
                            navigationGuards: ['AUTH', 'BA']

                        }
                    },
                    {
                        path: ROUTES.BA_COMPLETED_SUBMISSIONS_PATH,
                        name: ROUTES.BA_COMPLETED_SUBMISSIONS_NAME,
                        component: () => import('@/views/BACompletedSubmissions.vue'),
                        meta: {
                            navigationGuards: ['AUTH', 'BA']

                        }
                    },
                    {
                        path: ROUTES.BA_DRAFTS_PATH,
                        name: ROUTES.BA_DRAFTS_NAME,
                        component: () => import('@/views/BADrafts.vue'),
                        meta: {
                            navigationGuards: ['AUTH', 'BA']

                        }
                    },
                    {
                        path: ROUTES.BA_INFLIGHT_SUBMISSIONS_PATH,
                        name: ROUTES.BA_INFLIGHT_SUBMISSIONS_NAME,
                        component: () => import('@/views/BAInFlightSubmissions.vue'),
                        meta: {
                            navigationGuards: ['AUTH', 'BA']

                        }
                    },
                    {
                        path: ROUTES.BA_NEW_SUBMISSION_REQUEST_PATH,
                        name: ROUTES.BA_NEW_SUBMISSION_REQUEST_NAME,
                        component: () => import('@/views/BANewSubmissionRequest.vue'),
                        meta: {
                            navigationGuards: ['AUTH', 'BA']

                        }
                    },
                    {
                        path: ROUTES.BA_PRODUCT_LINE_REPORT_PATH,
                        name: ROUTES.BA_PRODUCT_LINE_REPORT_NAME,
                        component: () => import('@/views/BAProductLineReport.vue'),
                        meta: {
                            navigationGuards: ['AUTH', 'BA']

                        }
                    },
                    {
                        path: ROUTES.BA_SYSTEM_ADMIN_PATH,
                        name: ROUTES.BA_SYSTEM_ADMIN_NAME,
                        component: () => import('@/views/BASystemAdmininstration.vue'),
                        meta: {
                            navigationGuards: ['AUTH', 'BA']

                        }
                    }
                ]
            }
        ]
    },
    {
        path: `/${ROUTES.PORTAL_SELECTION_PATH}`,
        name: ROUTES.PORTAL_SELECTION_NAME,
        component: () => import('@/views/PortalSelection.vue'),
        meta: {
            navigationGuards: ['AUTH']
        }
    },
    {
        path: `/${ROUTES.LICENSEE_SELECTION_PATH}`,
        name: ROUTES.LICENSEE_SELECTION_NAME,
        component: () => import('@/views/LicenseeSelection.vue'),
        meta: {
            navigationGuards: ['AUTH']
        }
    },
    {
        path: '/fact-sheets',
        name: 'FactSheets',
        component: () => import('@/views/FactSheets.vue'),
        meta: {
            navigationGuards: ['AUTH', 'SG']
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// navigation guards
router.beforeEach(async (to, from, next) => {
    if (to.path === `/${ROUTES.LOGIN_PATH}` || to.name === ROUTES.LOGIN_NAME || to.path === `/${ROUTES.LOGIN_REDIRECT_PATH}` || to.name === ROUTES.LOGIN_REDIRECT_NAME) {
        return next();
    }

    const proxyUser = computed(() => store.getters['auth/getUserProxyInfo']);
    const currentUserPermissions = store.getters['auth/getUserPermissions'] || [];
    const currentUserRoles = store.getters['auth/getUserRoles'] || [];
    const currentUserLicensees = store.getters['auth/getUserLicensees'] || [];
    let userPermissions = [];
    let userRoles = [];
    let userLicensees = [];

    if (proxyUser.value?.roles) {
        userPermissions = proxyUser.value?.roles;
        userRoles = proxyUser.value?.roles;
        userLicensees = proxyUser.value?.licensees;
    } else {
        userPermissions = currentUserPermissions;
        userRoles = currentUserRoles;
        userLicensees = currentUserLicensees
    }

    const selectedLicenseeName = store.getters['auth/getSelectedLicensee']?.licenseeName || '';

    const hasBaAccess = userRoles.some(r => Object.values(BA_ROLES).includes(r));
    const hasSgAccess = proxyUser.value?.roles ? proxyUser.value?.roles?.includes('Style Guide Access') : userPermissions.includes(PERMISSIONS.SG_ACCESS_PERMISSION);

    // ------------------ CHECK CURRENT USER ------------------
    const currentUser = store.getters['auth/getUserInfo'] || {};
    if (
        currentUser.status !== 'Y' ||
        (
            currentUser.allowedWithoutLicId !== 'Y' && (!userLicensees || !userLicensees.length)
        )
    ) {
        console.error('User is either DISABLED or does not have the required permissions.');
        return next({ name: ROUTES.LOGIN_NAME });
    }

    // ------------------ AUTH CHECK ------------------
    const matchedRoute = to.matched.find(route => route.path === to.path);
    const routeMeta = matchedRoute ? matchedRoute.meta : null;
    if (routeMeta && Object.keys(routeMeta).length) {
        // BA navigation guard
        const isBaAccessNeeded = routeMeta.navigationGuards && routeMeta.navigationGuards.length ? routeMeta.navigationGuards.some(nGuard => nGuard === 'BA') : false;
        if (isBaAccessNeeded && !hasBaAccess) return next({ name: ROUTES.PORTAL_SELECTION_NAME });

        // SG navigation guard
        const isSgAccessNeeded = routeMeta.navigationGuards && routeMeta.navigationGuards.length ? routeMeta.navigationGuards.some(nGuard => nGuard === 'SG') : false;
        if (isSgAccessNeeded && !hasSgAccess) return next({ name: ROUTES.PORTAL_SELECTION_NAME });

        // auth navigation guard
        const isAuthRequired = routeMeta.navigationGuards && routeMeta.navigationGuards.length ? routeMeta.navigationGuards.some(nGuard => nGuard === 'AUTH') : false;
        if (isAuthRequired) {
            const tmp = await areOktaTokensValid();
            if (tmp) {
                // return next();
            } else return next({ name: ROUTES.LOGIN_NAME });
        }

        // auto redirect to home guard
        const isRedirectToHomeRequired = routeMeta.navigationGuards && routeMeta.navigationGuards.length ? routeMeta.navigationGuards.some(nGuard => nGuard === 'AUTOREDIRECTHOME') : false;
        if (isRedirectToHomeRequired) {
            const tmp = await areOktaTokensValid();
            if (tmp) return '/';
            else {
                // return next();
            }
        }
    }

    // ------------------ PORTAL SELECTION ------------------
    if (to.path === `/${ROUTES.PORTAL_SELECTION_PATH}` || to.name === ROUTES.PORTAL_SELECTION_NAME) {
        if (userLicensees && userLicensees.length > 1 && !selectedLicenseeName) return next({ name: ROUTES.LICENSEE_SELECTION_NAME });

        if (hasSgAccess && hasBaAccess) return next();
        else if (hasSgAccess && !hasBaAccess) return next({ name: ROUTES.STYLE_GUIDES_NAME });
        else if (!hasSgAccess && hasBaAccess) return next({ name: ROUTES.BRAND_ASSURANCE_NAME });
        else return next({ name: ROUTES.LOGIN_NAME });
    }

    // ------------------ BA ROUTE ------------------
    if ((to.path === ROUTES.BRAND_ASSURANCE_PATH || to.name === ROUTES.BRAND_ASSURANCE_NAME)) {
        if (userRoles && userRoles.length && userRoles.length === 1 && userRoles[0] === BA_ROLES.BA_RO_ROLE) {
            return next({ name: ROUTES.BA_ADVANCE_SEARCH_NAME });
        } else if (userRoles && userRoles.length && userRoles.length === 1 && userRoles[0] === BA_ROLES.COLLABORATOR_ROLE) {
            return next({ name: ROUTES.BA_ADVANCE_SEARCH_NAME });
        } else {
            return next({ name: ROUTES.BA_INBOX_NAME });
        }
    }

    return next();
});

export default router;
