import { deleteSavedSearchService, fetchSavedSearchesService, saveAssetsSearchService } from '@/api/requests/styleGuidesSavedSearchRequests.js';

export default {
    namespaced: true,

    state () {
        return {
            savedSearches: null,
            searchParams: {},
            searchWithInParams: {},
            searchWithInFlag: false,
            searchInitiated: false
        };
    },

    getters: {
        getSavedSearches (state) {
            return state.savedSearches;
        },
        getSearchParams (state) {
            return state.searchParams;
        },
        getSearchWithInParams (state) {
            const tmp = state.searchWithInParams
            Object.keys(tmp).forEach(key => tmp[key] === undefined && delete tmp[key])
            return tmp;
        },
        getSearchWithInFlag (state) {
            return state.searchWithInFlag;
        },
        getSearchInitiated (state) {
            return state.searchInitiated;
        }
    },

    mutations: {
        setSavedSearches (state, data) {
            state.savedSearches = data;
        },
        removeSavedSearch (state, searchOption) {
            state.savedSearches = state.savedSearches.filter((search) => search.id ? search.id !== searchOption.id : search.searchKey !== searchOption.searchKey);
        },
        addSearch (state, data) {
            if (state.savedSearches === null) state.savedSearches = [];
            let idx = state.savedSearches.findIndex(e => e.id === data.id)
            if (idx >= 0) state.savedSearches.splice(idx, 1, data)
            else state.savedSearches.push(data)
        },
        setSearchParams (state, newParams) {
            state.searchParams = newParams;
        },
        setSearchWithInParams (state, data) {
            state.searchWithInParams = data;
        },
        updateSearchParams (state, params) {
            state.searchParams = {
                ...state.searchParams,
                ...params
            };
        },
        setSearchWithInFlag (state, payload) {
            state.searchWithInFlag = payload.searchWithInFlag;
            if (!payload.searchWithInFlag || !payload.selectedNodeStack || !payload.selectedNodeStack.length){
              let tmpParams = {}
              for (let i=0; i < 12; i++) tmpParams[`mrlc_attr_tier_${i+1}`] = undefined
              state.searchWithInParams = {...state.searchWithInParams, ...tmpParams}
            } else {
              const { selectedNodeStack, searchWithInFlag } = payload
              let tmpParams = {}
              for (let i=0; i < selectedNodeStack.length; i++){
                tmpParams[`mrlc_attr_tier_${i+1}`] = selectedNodeStack[i].title
              }
              state.searchWithInParams = {...state.searchWithInParams, ...tmpParams}
            }

        },
        setSearchInitiated (state, isSearchInitiated) {
            state.searchInitiated = isSearchInitiated;
        },
        clearSearchFilters (state) {
            const currentParams = { ...state.searchParams }
            state.searchParams = {};

            // Browsing SG
            if (!state.searchInitiated) {
              Object.keys(currentParams).filter(e => e.includes('_tier_')).forEach(k => state.searchParams[k] = currentParams[k])
            }
            // Searching Assets
            else {
              if (currentParams.keyword)
                state.searchParams.keyword = `${currentParams.keyword}`
              if (currentParams.styleGuideName)
                state.searchParams.styleGuideName = currentParams.styleGuideName
              if (currentParams.descriptiveKeywords)
                state.searchParams.descriptiveKeywords = currentParams.descriptiveKeywords
            }

        }
    },

    actions: {
        async fetchSavedSearches ({ commit }) {
            const { data: { data } } = await fetchSavedSearchesService();
            commit('setSavedSearches', data);
        },
        async deleteSavedSearch ({ commit }, payload) {
            await deleteSavedSearchService(payload.searchKey ? payload.searchKey : payload.id);
            commit('removeSavedSearch', payload);
        },
        async addNewSearch ({ commit }, payload) {
            const { data: { data } } = await saveAssetsSearchService(payload);
            if (data && data[0]) commit('addSearch', {
              id: data[0].searchKey,
              name: data[0].searchName,
              searchParams: data[0].searchJson
            });
        },
        saveSearchParams ({ commit }, params) {
            if (params && Object.keys(params).length) {
                commit('updateSearchParams', params);
            }
        },
        clearSavedSearches ({ commit }) {
            commit('setSavedSearches', null);
        },
        clearSearchParams ({ commit }) {
            commit('setSearchParams', {});
        },
        setSearchWithInFlag ({ commit }, payload) {
            commit('setSearchWithInFlag', payload);
        },
        updateSearchInitiated ({ commit }, { isSearchInitiated }) {
            commit('setSearchInitiated', isSearchInitiated);
        },
        clearAllSearchFilters ({ commit }) {
            commit('clearSearchFilters');
        },
        resetAssetsSearch ({ commit }) {
            commit('setSearchParams', {});
            commit('setSearchInitiated', false);
        }
    }
};
