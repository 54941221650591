import store from '@/store/store.js';
import okta from '@/lib/okta.js';

let refreshInProgress = false;
let userInfo;

const isTokenExpired = (token) => {
    if (!token || !token.expiresAt) return false;
    return Date.now() <= new Date(token.expiresAt * 1000);
    
};

const isTokenRefreshNeded = () => {
    return !isTokenExpired(userInfo.oktaIdToken) || !isTokenExpired(userInfo.oktaAccessToken) || userInfo.oktaAccessToken.accessToken !== userInfo.token;
};

const checkOktaTokens = async () => {
    if (!userInfo) {
        const portalLS = JSON.parse(localStorage.getItem(`asgard_${process.env.VUE_APP_OKTA_CLIENT_ID}_auth`));
        userInfo = portalLS && portalLS.auth && portalLS.auth.userInfo ? portalLS.auth.userInfo : null;
    }

    if (!userInfo || !userInfo.oktaAccessToken || !userInfo.oktaIdToken || !userInfo.token) throw new Error('User Info is not valid');

    // Check Okta Tokens .. and ASGARD API token .. Refresh if needed
    if (isTokenRefreshNeded()) {
    // Get a Lock for doing the refresh .. in case there are multiple calls simultaneously .. keep looping for about 10 seconds
        //const waitCycle = 0;
        let waitCycle = 0;  // Change const to let.

        if (refreshInProgress) do {
            console.log('Waiting for 1.5 seconds ... ');
            // Wait two seconds
            await new Promise(r => setTimeout(r, 1500))
            waitCycle++
        } while (refreshInProgress && waitCycle < 10);

        // Try to refresh the tokens
        if (!refreshInProgress && isTokenRefreshNeded()) {
            try {
                refreshInProgress = true;
                // console.log(`userInfo BEFORE refresh ..`)
                // console.log(userInfo)

                const updatedTokens = await okta.refreshIdAndAccessToken();
                console.log(`Okta ID Token Renewed .. for ${Math.floor((updatedTokens.idToken.expiresAt - Math.floor(Date.now() / 1000)) / 60)} min`);
                console.log(`Okta Access Token Renewed .. for ${Math.floor((updatedTokens.accessToken.expiresAt - Math.floor(Date.now() / 1000)) / 60)} min`);
                const userInfoPayload = {
                    ...userInfo,
                    oktaIdToken: updatedTokens.idToken,
                    oktaAccessToken: updatedTokens.accessToken,
                    token: updatedTokens.accessToken.accessToken
                };

                // Update the Store .. and fetch user permissions
                // NOTE: DO NOT ADD AN AWAIT HERE ... otherwise the waiting calls will have to wait for the fetchUserDetails
                store.dispatch('auth/loginUser', { userInfo: userInfoPayload });

                // Set the userInfo again after token refresh
                const tmp = JSON.parse(localStorage.getItem(`asgard_${process.env.VUE_APP_OKTA_CLIENT_ID}_auth`));
                userInfo = tmp && tmp.auth && tmp.auth.userInfo;
                // console.log(`userInfo AFTER refresh ..`)
                // console.log(userInfo)

                // check the tokens
                if (!isTokenExpired(userInfo.oktaIdToken) || !isTokenExpired(userInfo.oktaAccessToken)) throw new Error('Could not refresh the Okta Tokens');
            } catch (err) {
                console.log(`Problem in renewing the tokens .. ${err}`);
                throw err;
            } finally {
                refreshInProgress = false;
            }
        }
    }
};

// ----- ----- ----- ----- EXPORTED METHODS ----- ----- ----- ----- ----- -----

export async function resetUserInfo () {
    userInfo = null;
}

export async function areOktaTokensValid () {
    try { await checkOktaTokens(); }
    catch (e) { return false; }
    return true;
}

export async function addTokenInRequestHeader (axiosConfig) {
    try {
        await checkOktaTokens();

        // Set the header .. i.e. the access token for the ASGARD API
        axiosConfig.headers.common.Authorization = `Bearer ${userInfo.token}`;
    } catch (authError) {
        console.log(`Problem in setting token in Header .. ${authError}`);
        window.location.href = '/login';
    }
    return axiosConfig;
};
