import { fetchUserDetailsByUserNameService } from '@/api/requests/usersRequests';
import { resetUserInfo } from '@/helpers/authCheck.js';
import okta from '@/lib/okta.js';
import * as BA_ROLES from '@/constants/ba-roles.js';

const reviewerRoles = [
    BA_ROLES.BAC_REVIEWER_ROLE,
    BA_ROLES.LEGAL_ROLE,
    BA_ROLES.CREATIVE_ROLE,
    BA_ROLES.SAFETY_ROLE,
    BA_ROLES.BAC_ADMIN_ROLE,
    BA_ROLES.BAC_FINAL_ROLE,
    BA_ROLES.BAC_SUPERVISOR_ROLE,
    BA_ROLES.SOCIAL_REVIEWER_ROLE,
    BA_ROLES.BA_ADMIN_ROLE,
    BA_ROLES.SKU_ADMIN_ROLE,
    BA_ROLES.GAMES_REVIEWER_ROLE,
    BA_ROLES.DIGITAL_REVIEWER_ROLE
];

export default {
    namespaced: true,

    state () {
        return {
            userInfo: null,
            userProxyInfo: null,
            selectedLicensee: null
        };
    },

    getters: {
        getUserInfo (state) {
            return state.userInfo;
        },
        getUserProxyInfo (state) {
            return state.userProxyInfo;
        },
        getSelectedLicensee (state) {
            return state.selectedLicensee;
        },
        getUserId (state) {
            return state?.userInfo?.preferred_username;
        },
        getUserPermissions (state) {
            return state.userInfo?.permissions?.map(p => p.toLowerCase()) || [];
        },
        getUserRoles (state) {
            return state.userInfo?.roles || [];
        },
        getUserLicensees (state) {
            if (!state.userInfo) return [];
            return state.userInfo.licensees && state.userInfo.licensees.length ? state.userInfo.licensees
                : [];
        },
        doesUserHaveAssociatedLicensees (state) {
            const tmpRoles = (state?.userInfo?.roles || []).filter(r => reviewerRoles?.includes(r));
            return state?.userInfo && state?.userInfo?.licensees && state?.userInfo?.licensees?.length > 0 &&
            state?.userInfo?.allowedWithoutLicId !== 'Y' && tmpRoles?.length === 0;
        },
        hasSomeReviewerRole (state) {
            const tmpRoles = (state?.userInfo?.roles || []).filter(r => reviewerRoles?.includes(r));
            return tmpRoles?.length > 0;
        },
        isReadOnlyUser (state) {
            return state?.userInfo && state?.userInfo?.roles && state?.userInfo?.roles?.length === 1 &&
            state?.userInfo?.roles?.map(r => r?.toLowerCase())?.includes('ba read only');
        }
    },

    mutations: {
        setUserInfo (state, userInfo) {
            if (userInfo && userInfo?.preferred_username?.includes('@')) {
                userInfo.preferred_username = userInfo?.preferred_username?.split('@')[0];
            }
            state.userInfo = userInfo;
        },
        setUserProxyInfo (state, userProxyInfo) {
            if (userProxyInfo && userProxyInfo?.preferred_username?.includes('@')) {
                userProxyInfo.preferred_username = userProxyInfo?.preferred_username?.split('@')[0];
            }
            state.userProxyInfo = userProxyInfo;
        },
        setSelectedLicensee (state, licensee) {
            state.selectedLicensee = licensee;
        }
    },

    actions: {
        async loginUser ({ commit, getters }, { userInfo }) {
            if (!userInfo || !userInfo?.preferred_username) return;

            // console.log(`Login userInfo ${JSON.stringify(userInfo)}`);

            commit('setUserInfo', userInfo);

            return fetchUserDetailsByUserNameService({ username: userInfo.preferred_username })
                .then(resp => {
                    const params = { username: userInfo.preferred_username };
                    const { data: { data: { groups, roles, permissions, licensees, allowedWithoutLicId, status } } } = resp;
                    const retVal = { ...userInfo, groups, roles, permissions, licensees, allowedWithoutLicId, status };
                    // console.log(`Logged in retVal ${JSON.stringify(retVal)}`);
                    commit('setUserInfo', retVal);

                    if (retVal.status !== 'Y') throw new Error('User account is disabled. Please reach out to LQuestions@marvel.com for assistance.');
                    if (retVal.allowedWithoutLicId !== 'Y' && (!getters.getUserLicensees || !getters.getUserLicensees.length)) throw new Error('You currently don\'t have any licensee associated with your login. Please reach out to LQuestions@marvel.com for assistance.');

                    if (getters.getUserLicensees && getters.getUserLicensees.length === 1) {
                        commit('setSelectedLicensee', getters.getUserLicensees[0]);
                    }

                    return retVal;
                })
                .catch(err => {
                    console.log(`Preblem in fetching user details .. ${err}`);
                    throw err;
                });
        },
        async logoutUser ({ commit, dispatch }) {
            try {
                commit('setUserInfo', null);
                commit('setSelectedLicensee', null);
                resetUserInfo();
                dispatch('assetsSearch/resetAssetsSearch', null, { root: true });
                dispatch('assets/resetAssets', null, { root: true });
                dispatch('assetsSearch/clearSavedSearches', null, { root: true });
                dispatch('activeDownloads/clearDownloadsList', null, { root: true });
                dispatch('basket/clearBasketCache', null, { root: true });
                dispatch('styleGuideTree/resetSelectedNodeStack', null, { root: true });
                dispatch('styleGuideTree/resetStyleGuideTree', null, { root: true });

                await okta.logoutUser();
            } catch (e) {
            }
        },
        async revokeUserProxy ({ commit, dispatch }) {
            try {
                commit('setUserProxyInfo', null);
            } catch (e) {
                console.log(`Preblem in revoking proxy user details .. ${e}`);
            }
        },
        setSelectedLicensee ({ commit }, licensee) {
            commit('setSelectedLicensee', licensee);
        }
    }
};
