import { BASE_API_ENDPOINT, LICENSEES_ENDPOINT, DRM_ENDPOINT, DRM_USER_LICENSEES, DRM_CONTRACT_SYNC_ENDPOINT, DRM_REFRESH_VIEWS_ENDPOINT, DRM_ARTICLES_ENDPOINT, LICENSEE_CONTRACT_ENDPOINT, DRM_TERRITORIES_ENDPOINT, DRM_CHANNELS_ENDPOINT, LICENSEES_DETAILS_ENDPOINT, STYLE_GUIDE_CHARACTERS_ENDPOINT, STYLE_GUIDES_ENDPOINT, DRM_CONTRACTS_ENDPOINT, DRM_REPLACE_CONTRACT_ENDPOINT, SUBMISSION_CONTRACTUAL_SAMPLES_DETAILS, ENFORCE_SAMPLE_ENDPOINT } from '@/api/endpointConstants.js';
import { addTokenInRequestHeader } from '@/helpers/authCheck.js';
import axios from 'axios';
import { filterObject } from '@/helpers/util';

// intialize local instance  for drm services
const drmAxiosInstance = axios.create({
    baseURL: BASE_API_ENDPOINT + DRM_ENDPOINT
});

// request interceptor
drmAxiosInstance.interceptors.request.use(request => {
    // add token in header
    request = addTokenInRequestHeader(request);
    return request;
});

export function fetchDrmLicenseesListService (params) {
    return drmAxiosInstance({
        method: 'GET',
        url: `${LICENSEES_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function fetchSubmissionsContractualSamplesService (contractNumber) {
    return drmAxiosInstance({
        method: 'GET',
        url: `${DRM_CONTRACTS_ENDPOINT}/${contractNumber}${SUBMISSION_CONTRACTUAL_SAMPLES_DETAILS}`
    });
}

export function fetchDrmLicenseesContractListService (id, params) {
    if (!id) return { data: { data: [] } }
    return drmAxiosInstance({
        method: 'GET',
        url: `${LICENSEE_CONTRACT_ENDPOINT}/${id}`,
        params: filterObject(params)
    });
}

export function fetchDrmStyleGuideContractListService (params) {
    // if (!params?.contractNumber) return { data: { data: [] } };
    return drmAxiosInstance({
        method: 'GET',
        url: `${DRM_CONTRACTS_ENDPOINT}/${params.contractNumber ? params.contractNumber : '00000000'}${STYLE_GUIDES_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function fetchDrmStyleGuideCharactersListService (params, id) {
    if (!id) return { data: { data: [] } };
    return drmAxiosInstance({
        method: 'GET',
        url: `${DRM_CONTRACTS_ENDPOINT}/${id}${STYLE_GUIDE_CHARACTERS_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function fetchDrmLicenseeDetailsService (id) {
    if (!id) return { data: { data: [] } };
    return drmAxiosInstance({
        method: 'GET',
        url: `${LICENSEES_ENDPOINT}/${id}${LICENSEES_DETAILS_ENDPOINT}`
    });
}

export function fetchDrmTerritoiesDetailsService (params, id) {
    if (!id) return { data: { data: [] } };
    return drmAxiosInstance({
        method: 'GET',
        url: `${DRM_CONTRACTS_ENDPOINT}/${id}${DRM_TERRITORIES_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function fetchDrmChannlesDetailsService (params, id) {
    if (!id) return { data: { data: [] } };
    return drmAxiosInstance({
        method: 'GET',
        url: `${DRM_CONTRACTS_ENDPOINT}/${id}${DRM_CHANNELS_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function fetchDrmArticlesDetailsService (params, id) {
    // if (!id) return { data: { data: [] } };
    return drmAxiosInstance({
        method: 'GET',
        url: `${DRM_CONTRACTS_ENDPOINT}/${id ? id : '00000000'}${DRM_ARTICLES_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function fetchDrmreplacementContractService (params) {
    return drmAxiosInstance({
        method: 'GET',
        url: `${DRM_REPLACE_CONTRACT_ENDPOINT}`,
        params: filterObject(params)
    });
}

export function fetchDrmLicenseeContractDetailsService (id) {
    if (!id) return { data: { data: [] } };
    return drmAxiosInstance({
        method: 'GET',
        url: `${DRM_CONTRACTS_ENDPOINT}/${id}${LICENSEES_DETAILS_ENDPOINT}`
    });
}

export function drmContractSyncService (data) {
    return drmAxiosInstance({
        method: 'POST',
        url: `${DRM_CONTRACT_SYNC_ENDPOINT}`,
        data
    });
}

export function drmEnforceSampleService (data) {
    return drmAxiosInstance({
        method: 'POST',
        url: `${ENFORCE_SAMPLE_ENDPOINT}`,
        data
    });
}

export function drmRefreshViewsService () {
    return drmAxiosInstance({
        method: 'GET',
        url: `${DRM_REFRESH_VIEWS_ENDPOINT}`
    });
}

export function fetchUserLicenseesListByLicenseeIdService (licenseeId) {
    return drmAxiosInstance({
        method: 'GET',
        url: `${DRM_USER_LICENSEES}`,
        params: filterObject({ licenseeId })
    });
}
