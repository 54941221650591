export const LICENSEE_ROLE = 'Licensee';
export const BAC_REVIEWER_ROLE = 'BAC Reviewer';
export const REPORTING_ACCESS_ROLE = 'Report Access Reviewer';
export const LEGAL_ROLE = 'Legal Reviewer';
export const CREATIVE_ROLE = 'Creative Reviewer';
export const SAFETY_ROLE = 'Safety Reviewer';
export const BAC_ADMIN_ROLE = 'BAC Admin'; // ...BAC_FINAL...
export const BAC_SUPERVISOR_ROLE = 'BAC Supervisor';
export const SOCIAL_REVIEWER_ROLE = 'Social Reviewer';
export const COLLABORATOR_ROLE = 'Collaborator';
export const BA_ADMIN_ROLE = 'BA Administrator';
export const SKU_ADMIN_ROLE = 'SKU Admin';
export const BA_RO_ROLE = 'BA Read Only';
export const GAMES_REVIEWER_ROLE = 'Games Reviewer';
export const DIGITAL_REVIEWER_ROLE = 'Digital Product Reviewer';
// export const MASTER_COLLABORATOR_ROLE = 'Master Collaborator';
// export const BAC_FINAL_ROLE = 'BAC Final'; // not using this role
