import { createStore, createLogger } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import activeDownloads from './modules/activeDownloads.js';
import assets from './modules/assets.js';
import assetsSearch from './modules/assetsSearch.js';
import auth from './modules/auth.js';
import baCollaborations from './modules/baCollaborations.js';
import baLicensee from './modules/baLicensee.js';
import baProductLineReport from './modules/baProductLineReport.js';
import basket from './modules/basket.js';
import baStyleGuides from './modules/baStyleGuides.js';
import baSubmissions from './modules/baSubmissions.js';
import baSubmissionRouting from './modules/baSubmissionRouting.js';
import styleGuideTree from './modules/styleGuideTree.js';
import users from './modules/users.js';
import userPrefs from './modules/userPrefs.js';
import upload from './modules/upload.js';

const plugins = [
    createPersistedState({
        key: `asgard_${process.env.VUE_APP_OKTA_CLIENT_ID}_auth`,
        paths: ['auth']
    }),
    createPersistedState({
      key: `asgard_${process.env.VUE_APP_OKTA_CLIENT_ID}_userPrefs`,
      paths: ['userPrefs']
  })
];

// @NOTE: remove this plugin once vue dev tools(browser extension) have support for vuex 4
// if (process.env.NODE_ENV !== 'production') {
//     plugins.push(createLogger());
// }

export default createStore({
    modules: {
        activeDownloads,
        assets,
        assetsSearch,
        auth,
        baCollaborations,
        baLicensee,
        baProductLineReport,
        basket,
        baStyleGuides,
        baSubmissions,
        baSubmissionRouting,
        styleGuideTree,
        users,
        userPrefs,
        upload
    },
    plugins
});
