import { ref, computed, reactive } from 'vue';
import { useStore } from 'vuex';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { SUCCESS, ERROR, WARNING, ATTACHMENTS_UPLOADED_SUCCESSFULLY, ATTACHMENTS_UPLOADED_FAILED } from '@/constants/alerts.js';
import useSubmissionForm from './submissionForm.js';
import axios from 'axios';
import pLimit from 'p-limit';

const uploadFileTracker = ref({});
const isUploadPopUpVisible = ref(false);
const collapsePopup = ref(true);
const isFetchingUploadedFiles = ref(false);

export default () => {
    const store = useStore();
    const { notificationsStack } = useToastNotifications();
    const { submissionForm } = useSubmissionForm();

    const concurrentlyExecutedLimit = pLimit(12);

    const uploadDataFromStore = computed(() => store.getters['upload/getUploadData']);

    const pushFilesForAssetPlaceholders = async (newFilesList) => {
        if (!uploadDataFromStore.value || !uploadDataFromStore.value) return;

        try {
            isUploadPopUpVisible.value = true;
            uploadDataFromStore.value.forEach((file, i) => {
                try {
                    const fileBlob = newFilesList.find(newFile => newFile.filename.toLowerCase() === file.filename.toLowerCase()).file;
                    if (!fileBlob) throw new Error(`File not found .. ${file.filename.toLowerCase()}`);

                    let useSinglePart = false;
                    if (file.url) {
                        useSinglePart = true;
                        file.urls = [file.url];
                    }

                    // console.log(`Adding ${file.filename.toLowerCase()} to key ${file.objectId} .. Single Part : ${useSinglePart}`)
                    uploadFileTracker.value[file.objectId] = { file, progress: file.urls.map(f => 0) };

                    if (useSinglePart) tenovosUploadFileSinglePart(file, fileBlob);
                    else tenovosUploadFileMultiPart(file, fileBlob);
                } catch (fileUploadErr) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: `${fileUploadErr}`
                    });
                }
            });
            isUploadPopUpVisible.value = true;
            // setTimeout(() => { collapsePopup.value = false }, 3000)

            await fetchUploadFiles();
        } catch (err) {
            console.error(err);
            notificationsStack.value.push({
                type: ERROR,
                message: ATTACHMENTS_UPLOADED_FAILED
            });
        } finally {
        }
    };

    const fetchUploadFiles = async () => {
        try {
            isFetchingUploadedFiles.value = true;
            await store.dispatch('upload/fetchUploadedFiles', {
                params: {
                    submissionId: submissionForm.submissionId
                }
            });
        } catch (err) {
            console.error(err);
        } finally {
            isFetchingUploadedFiles.value = false;
        }
    };

    const tenovosUploadFileSinglePart = (file, fileBlob) => {
        let uploadStatus = 'N';
        let errorMessage = '';
        // Add to the concurrently executed queue
        const uploadPromise = concurrentlyExecutedLimit(() => {
            // Make the PUT call
            return axios.put(file.urls[0], fileBlob, {
                onUploadProgress: (progressEvent) => {
                    const tmpProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                    uploadFileTracker.value[file.objectId].progress[0] = tmpProgress;
                    if (tmpProgress >= 100) { console.log(`Active Count ${concurrentlyExecutedLimit.activeCount} .. Pending Count ${concurrentlyExecutedLimit.pendingCount}`); };
                }
            });
        });

        // Add a handler
        uploadPromise.then((putResp) => {
            uploadStatus = 'Y';
            notificationsStack.value.push({
                type: SUCCESS,
                message: `${ATTACHMENTS_UPLOADED_SUCCESSFULLY} - ${file.filename.toLowerCase()}`
            });
        }).catch((uploadErr) => {
            console.error(uploadErr);
            uploadStatus = 'N';
            errorMessage = uploadErr;
            notificationsStack.value.push({
                type: ERROR,
                message: `${ATTACHMENTS_UPLOADED_FAILED} - ${file.filename.toLowerCase()} - ${uploadErr}`
            });
        }).finally(async () => {
            //  update the upload status in db
            await updateAssetUpload(file, uploadStatus, `${errorMessage}`);
            console.log(`Updated upload File Single Part status ${uploadStatus} to ${JSON.stringify(file.filename.toLowerCase())}`);
        });
    };

    const tenovosUploadFileMultiPart = (file, fileBlob) => {
        const uploadPromises = [];
        let uploadStatus = 'N';
        let errorMessage = '';

        //  console.log(`Pushing in tenovosUploadFileMultiPart .. ${file.filename?.toLowerCase()}... file urls.. ${file.urls} `)

        // Break the fileBlob into chunks .. and push the chunks per url .. Add a promise for each URL of the file
        const chunkSize = 100 * 1024 * 1024; // 100 MB
        let start = 0;
        file.urls.forEach((urlData, urlIndex) => {
            // Create a chunk
            const chunkEnd = Math.min(start + chunkSize, fileBlob.size);
            const chunk = fileBlob.slice(start, chunkEnd);
            // console.log(`Chunk Number ${Math.ceil(chunkEnd/chunkSize)} .. Length ${chunk.size}`)
            start = chunkEnd;

            // Create a promise for the url
            const uploadPromise = concurrentlyExecutedLimit(() => {
                // console.log(`Pushing File part .. ${file.filename.toLowerCase()} .. PART ${urlIndex+1}`)
                return axios.put(urlData.url, chunk, {
                    // Update the progress for the specific URL (i.e. with urlIndex)
                    onUploadProgress: (progressEvent) => {
                        const tmpProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                        uploadFileTracker.value[file.objectId].progress[urlIndex] = tmpProgress;
                    }
                });
            });
            // Push the url to promises
            uploadPromises.push(uploadPromise);
        });

        let parts = '';
        // Wait for all the files to upload .. then complete the upload
        Promise.all(uploadPromises).then(async (putResp) => {
            parts = putResp.map((resp, respIndex) => ({ partNumber: respIndex + 1, etag: JSON.parse(resp.headers.etag) }));
            const finishResp = await uploadAttachment(parts, file);
            uploadStatus = 'Y';
            notificationsStack.value.push({
                type: SUCCESS,
                message: `${ATTACHMENTS_UPLOADED_SUCCESSFULLY} - ${file.filename.toLowerCase()}`
            });
        }).catch((uploadErr) => {
            console.log(`Upload attachment errored out for ... ${JSON.stringify(file.filename.toLowerCase())}  ...  uploadErr ... ${JSON.stringify(uploadErr)}`);
            errorMessage = `First Attempt ... ${uploadErr.message}`;
            // try reattempt and wait for the response
            // if it fails push the notification and mark as failed in DB
            try {
                const secondFinishResp = uploadAttachment(parts, file);
                console.log(`Re-try multipart response... ${JSON.stringify(secondFinishResp)}`);
                uploadStatus = 'Y';
                delete uploadFileTracker.value[file.objectId];
            } catch (uploadError) {
                uploadStatus = 'N';
                errorMessage = errorMessage.concat(`--- Second Attempt... ${uploadError.message}`);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${ATTACHMENTS_UPLOADED_FAILED} - ${file.filename.toLowerCase()} - ${uploadError}`
                });
            }
        }).finally(async () => {
            //  update the upload status in db
            await updateAssetUpload(file, uploadStatus, `${errorMessage}`);
            console.log(`Updated upload file multipart status ${uploadStatus} to ${JSON.stringify(file.filename.toLowerCase())}`);
            file.uploadStatus = uploadStatus;
            file.errorMessage = `${errorMessage}`;
        });

        async function uploadAttachment () {
            try {
            //  console.log(`Upload attachment called for ... ${JSON.stringify(file.filename.toLowerCase())}`);
                const payload = {
                    filename: file.filename.toLowerCase(),
                    // objectId: file.objectId,
                    uploadId: file.uploadId,
                    originalFileId: file.originalFileId,
                    fileId: file.fileId,
                    action: 'complete',
                    filesize: fileBlob.size,
                    parts
                };
                // call 'complete' upon upload of all the parts of the file
                let finishResp = await store.dispatch('upload/finishMultiPart', { payload });
                console.log(`Upload Attachment Response ... ${JSON.stringify(finishResp?.data?.message)}`);
                return finishResp?.data?.message;
            } catch (inCompleteError) {
                uploadStatus = 'N';
                errorMessage = errorMessage.concat(`--- Incomplete Upload... ${inCompleteError.message}`);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${ATTACHMENTS_UPLOADED_FAILED} - ${file.filename.toLowerCase()} - ${inCompleteError}`
                });
                await updateAssetUpload(file, uploadStatus, `${errorMessage}`);
                console.log(`Updated incomplete upload file multipart status ${uploadStatus} to ${JSON.stringify(file.filename.toLowerCase())}`);
                file.uploadStatus = uploadStatus;
                file.errorMessage = `${errorMessage}`;
            }
        }
    };

    const updateAssetUpload = async (file, isUploaded, errorMessage) => {
        const payload = {
            filename: file.filename?.toLowerCase(),
            // objectId: file.objectId,
            uploadId: file.uploadId,
            errorMessage: `${errorMessage}`,
            originalFileId: file.originalFileId,
            fileId: file.fileId,
            isUploaded: isUploaded
        };
        console.log(`Asset Upload update payload ... ${JSON.stringify(payload)}`);
        store.dispatch('upload/updateUploadedAsset', { payload });
    };

    const getTrackerProgress = (tracker) => {
        try {
            return (tracker.progress.reduce((s, a) => s + a, 0) / tracker.file.urls.length);
        } catch (e) { return 0; }
    };

    const totalUploadProgress = computed(() => {
        let total = 0;
        Object.values(uploadFileTracker.value).forEach(tracker => total += getTrackerProgress(tracker));
        return Math.min(Math.ceil(total / Object.values(uploadFileTracker.value).length), 100);
    });

    const hideUploadPopUp = () => {
        try {
            Object.keys(uploadFileTracker.value).forEach(k => {
                const tracker = uploadFileTracker.value[k];
                if (getTrackerProgress(tracker) >= 100) delete uploadFileTracker.value[k];
            });

            if (Object.keys(uploadFileTracker.value).length) {
                collapsePopup.value = true;
                notificationsStack.value.push({
                    type: WARNING,
                    message: 'Waiting for uploads to finish.'
                });
            } else {
                isUploadPopUpVisible.value = false;
            }
        } catch (err) {
            console.error(err);
        }
    };

    return {
        totalUploadProgress,
        fetchUploadFiles,
        pushFilesForAssetPlaceholders,
        uploadFileTracker,
        hideUploadPopUp,
        isFetchingUploadedFiles,
        isUploadPopUpVisible,
        collapsePopup,
        updateAssetUpload,
        getTrackerProgress
    };
};
