// Submission Types Constants
export const GENERIC_SUBMISSION_TYPE = 'Generic';
export const STANDARD_SUBMISSION_TYPE = 'Standard';
export const OPA_SUBMISSION_TYPE = 'OPA Submission';
export const INTERACTIVE_SUBMISSION_TYPE = 'Interactive';
export const PRINT_ON_DEMAND_SUBMISSION_TYPE = 'Print on Demand Art';
export const PRESS_RELEASE_SUBMISSION_TYPE = 'Press Releases, Blogs, Media Alerts, any other PR';

// Dropdown key values Constants
export const TYPE_OPTIONS_DROPDOWN_KEY = 'typeOptions';
export const ASSET_TYPE_DROPDOWN_KEY = 'assetTypeOptions';
export const LINE_OF_BUSINESS_DROPDOWN_KEY = 'lineOfBusinessOptions';
export const GENERIC_CATEGORY_DROPDOWN_KEY = 'genericCategoryOptions';
export const CATEGORY_DROPDOWN_KEY = 'categoryOptions';
export const PRESS_RELEASE_DROPDOWN_KEY = 'pressReleaseCategoryOptions';
export const MATERIAL_SUPPLIED_DROPDOWN_KEY = 'materialSuppliedOptions';
export const ARTICLE_OPTIONS_DROPDOWN_KEY = 'articleOptions';
export const ARTICLE_NOTES_OPTIONS_DROPDOWN_KEY = 'articleNotesOptions';

// Params type Constants
export const ASSET_TYPE_PARAM_KEY = 'ASSET';
export const GEN_TYPE_PARAM_KEY = 'GEN_TYPE';
export const CAT_TYPE_PARAM_KEY = 'CATEGORY';
export const GEN_LOB_TYPE_PARAM_KEY = 'GEN_LOB';
export const GEN_CAT_TYPE_PARAM_KEY = 'GEN_CATEGORY';
export const MATERIALS_TYPE_PARAM_KEY = 'MATERIALS';

// Params code constants
export const OPA_CODE_PARAM_KEY = 'OPA';
export const POD_CODE_PARAM_KEY = 'POD';
export const INT_MATERIALS_CODE_PARAM_KEY = 'INTMATERIALS';
export const GEN_MATERIALS_CODE_PARAM_KEY = 'GENMATERIALS';
export const STD_MATERIALS_CODE_PARAM_KEY = 'STDMATERIALS';
export const PRESS_CATEGORY_CODE_PARAM_KEY = 'PRESSCATEGORY';

// Submission stage constants
export const CONCEPT_STAGE = 'Concept';
export const PRE_PRODUCTION_STAGE = 'Pre-Production';
export const PRODUCTION_STAGE = 'Production';
export const CONTRACTUAL_STAGE = 'Contractual';
export const ANNUALS_STAGE = 'Annual Samples';
export const FINAL_STAGE = 'Final';
export const REVISED_STAGE = 'Revised';
// everything otherthan above is intermediate..
export const INTERMEDIATE_STAGE = 'Intermediate';

// metarial supplied
export const DIGITAL_GOOD_METARIAL = 'Digital Goods';
export const PRINT_ON_DEMAND_SAMPLE_METARIAL = 'Print on Demand Samples';
export const PREMIUM_GWPS_PRODUCTS_METARIAL = 'Premiums/GWPs/Products';
export const SOCIAL_MEDIA_POST_METARIAL = 'Social media post';
export const DPA_GAMES_MATERIAL = 'DPA: Quiz/Game/Activity';
export const DPA_AR_MATERIAL = 'DPA: Social Media AR Interactive/Game';
export const DPA_UX_MATERIAL = 'DPA: App Concept/UX/Icon';
export const DPA_STICKER_MATERIAL = 'DPA: Social Media AR Filter/Sticker';
export const DPA_GOODS_MATERIAL = 'DPA: Goods/Web/E-Blast/Non-Social AR';
export const SOCIAL_DIGITAL_VIDEO_REVIEW = 'Digital Video Review';

// Submission step constants
export const LICENSEE_STEP = 'Licensee';
export const BAC_REVIEW_STEP = 'BAC Review';
export const LEGAL_REVIEW_STEP = 'Legal Review';
export const CONTRACT_COORDINATOR = 'Contract Coordinator';
export const CREATIVE_REVIEW_STEP = 'Creative Review';
export const SAFETY_STEP = 'Safety';
export const BAC_FINAL_STEP = 'BAC Final';
export const SOCIAL_REVIEW_STEP = 'Social Review';
export const DIGITAL_REVIEW_STEP = 'Digital Product Review';
export const GAMES_REVIEW_STEP = 'Games Review';
export const BAC_SUPERVISOR_STEP = 'BAC Supervisor';

// submisison form fields state
/** field is editable and required to be filled */
export const REQUIRED = 'R';
/** field is viewable, but not editable */
export const VIEW_ONLY = 'V';
/** field is editable, but not required to be filled */
export const EDITABLE = 'E';
/** field is invisible */
export const INVISIBLE = 'I';
/** button is active */
export const ACTIVE_BUTTON = 'A';

// submission status constants
export const APPROVE_STATUS = 'Approve';
export const APPROVE_WITH_CHANGES_STATUS = 'Approve with changes';
export const RESUBMIT_WITH_CHANGES_STATUS = 'Resubmit with changes';
export const REJECT_STATUS = 'Reject';
export const ROUTE_BACK_STATUS = 'Route Back';
export const SUBMITTED_STATUS = 'Submitted';
export const DRAFT_STATUS = 'Draft';
export const ASSIGN_TO_STATUS = 'Assign To';
export const ESCALATE_TO_SUPERVISOR_STATUS = 'Escalate to Supervisor';
export const SKIP_BAC_FINAL_STATUS = 'Skip BAC Final';
export const CANCEL_STATUS = 'Cancel';

// search mode for advanced search
export const ADVANCED_SEARCH_MODE = '1';
export const SIMPLE_SEARCH_MODE = '2';

// submission collaboration status
export const ACTIVE_COLLABORTION_STATUS = '1';
export const END_COLLABORTION_STATUS = '2';
export const DELETE_COLLABORTION_STATUS = '3';

// Preference messages
export const SAVE_PREFERENCE = 'Preference Saved Sucessfully';

export const DPA_MATERIALS = [
    'DPA: Quiz/Game/Activity', 'DPA: Social Media AR Interactive/Game', 'DPA: App Concept/UX/Icon', 'DPA: Social Media AR Filter/Sticker',
    'DPA: Goods/Web/E-Blast/Non-Social AR'
];

