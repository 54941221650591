import {
    assignTasksService, reAssignTasksService, claimTaskService, unclaimTaskService, fetchSubmissionsService,
    createCopyOfSubmissionService, deleteSubmissionService, deleteSubmissionSkuService, fetchCustomLinesSubmissionService, searchSubmissionsService,
    fetchSubmissionCategoriesService, updateLicenseeContactService, updateSubmissionContractService,
    fetchSubmissionDetailsService, submissionTasksService, fetchSubmissionComment, deleteSubmissionComment, generateIdentifierService,
    validateMultipleSKUService, validateSKUService, advanceSearchSubmissionsService, fetchSubmissionSavedSearchService,
    createSubmissionSavedSearchService, updateSubmissionSavedSearchService, deleteSubmissionSavedSearchService,
    masterDataSubmissionGameCodesService, downloadSubmissionReceiptService, downloadReviewTemplateService, initAnnualSamplesService,
    fetchPackagingSubmissionNumber, manualSKUPushService, resendNotificationService, searchExportExcelSubmissionsService
} from '@/api/requests/baSubmissionRequests.js';

import { fetchDrmreplacementContractService } from '@/api/requests/baDrmServicesRequests';

import { fetchSubmissionStagesService, fetchSubmissionStatusService, fetchSubmissionStepsService, fetchSubmissionTypesService, masterDataLicenseeRequestedStageService } from '@/api/requests/masterDataRequests.js';
import { updateSubmissionService } from '@/api/requests/transactionHandlerRequests';
import { BAC_FINAL_STEP, BAC_REVIEW_STEP, BAC_SUPERVISOR_STEP, CREATIVE_REVIEW_STEP, LEGAL_REVIEW_STEP, LICENSEE_STEP, SAFETY_STEP, SOCIAL_REVIEW_STEP, GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP } from '@/constants/submissions.js';
import * as BA_ROLES from '@/constants/ba-roles.js';

const reviewerRoles = [
    BA_ROLES.BAC_REVIEWER_ROLE,
    BA_ROLES.LEGAL_ROLE,
    BA_ROLES.CREATIVE_ROLE,
    BA_ROLES.SAFETY_ROLE,
    BA_ROLES.BAC_ADMIN_ROLE,
    BA_ROLES.BAC_FINAL_ROLE,
    BA_ROLES.BAC_SUPERVISOR_ROLE,
    BA_ROLES.SOCIAL_REVIEWER_ROLE,
    BA_ROLES.BA_ADMIN_ROLE,
    BA_ROLES.SKU_ADMIN_ROLE,
    BA_ROLES.GAMES_REVIEWER_ROLE,
    BA_ROLES.DIGITAL_REVIEWER_ROLE
];

export default {
    namespaced: true,

    state () {
        return {
            submissionsList: null,
            excelExportSubmissionsList: null,
            submissionSteps: null,
            submissionComments: null,
            submissionTypes: null,
            submissionStages: null,
            submissionStatusList: null,
            submissionCategories: null,
            submissionSavedSearches: null,
            newSavedSearch: null,
            searchedSubmissionId: null,
            newLicenseeContact: null,
            submissionContractList: null,
            updatedSubmissionContract: null,
            openedSubmissions: null,
            newSubmissionId: null,
            submissionTask: null,
            skuValidation: null,
            multipleSkuValidation: null,
            newGameCodes: null,
            newCustomLines: null,
            newCopySubmission: null,
            masterDataOptions: null,
            requestedStage: null,
            packagingSubmissions: null
        };
    },

    getters: {
        getSubmissionsList (state) {
            return state.submissionsList;
        },
        getExcelExportSubmissionsList (state) {
            return state.excelExportSubmissionsList;
        },
        getSubmissionSteps (state) {
            return state.submissionSteps;
        },
        getSubmissionComments (state) {
            return state.submissionComments;
        },
        getSubmissionTypes (state) {
            return state.submissionTypes;
        },
        getSubmissionSavedSearches (state) {
            return state.submissionSavedSearches;
        },
        getSearchedSubmissionId (state) {
            return state.searchedSubmissionId;
        },
        getSubmissionStages (state) {
            return state.submissionStages;
        },
        getSubmissionStatusList (state) {
            return state.submissionStatusList;
        },
        getSubmissionCategories (state) {
            return state.submissionCategories;
        },
        getNewLicenseeContact (state) {
            return state.newLicenseeContact;
        },
        getSubmissionContractList (state) {
            return state.submissionContractList;
        },
        getUpdatedSubmissionContract (state) {
            return state.updatedSubmissionContract;
        },
        getOpenedSubmissions (state) {
            return state.openedSubmissions;
        },
        getNewSubmissionId (state) {
            return state.newSubmissionId;
        },
        getSubmissionTask (state) {
            return state.submissionTask;
        },
        getSkuValidation (state) {
            return state.skuValidation;
        },
        getMultipleSkuValidation (state) {
            return state.multipleSkuValidation;
        },
        getNewSavedSearch (state) {
            return state.newSavedSearch;
        },
        getNewGameCodes (state) {
            return state.newGameCodes;
        },
        getLicenseeRequestedStage (state) {
            return state.requestedStage;
        },
        getCustomLines (state) {
            return state.newCustomLines;
        },
        getCopySubmission (state) {
            return state.newCopySubmission;
        },
        getMasterDataOptions (state) {
            return state.masterDataOptions;
        },
        getPackagingSubmissions (state) {
            return state.packagingSubmissions;
        }
    },

    mutations: {
        setExcelExportSubmissionsList (state, newExcelExportSubmissionsList) {
            // submitted item for review should not show 0 instead show string
            if (newExcelExportSubmissionsList?.data) {
                newExcelExportSubmissionsList.data.forEach(sub => {
                    if (sub.licenseeReqStage === '0') {
                        sub.licenseeReqStage = '';
                    }
                });
            }
            state.excelExportSubmissionsList = newExcelExportSubmissionsList;
        },
        setSubmissionsList (state, newSubmissions) {
            // submitted item for review should not show 0 instead show string
            if (newSubmissions?.data) {
                newSubmissions.data.forEach(sub => {
                    if (sub.licenseeReqStage === '0') {
                        sub.licenseeReqStage = '';
                    }
                });
            }
            state.submissionsList = newSubmissions;
        },
        updateSubmissionsList (state, newSubmissions) {
            newSubmissions.forEach(newSubmission => {
                const matchedSubmissionIndex = state.submissionsList.data.findIndex(submission => submission.id === newSubmission.id);
                if (matchedSubmissionIndex !== -1) {
                    state.submissionsList.data.splice(matchedSubmissionIndex, 1, newSubmission);
                } else {
                    state.submissionsList.data.push(newSubmission);
                }
            });
        },
        changeAssigneeInSubmissionsList (state, newSubmissions) {
            state.submissionsList.data = state.submissionsList.data.map(sub => {
                const matchedSubmissions = newSubmissions.find(submission => submission.submissionId === sub.submissionId);
                if (matchedSubmissions) {
                    return {
                        ...sub,
                        assignUser: matchedSubmissions.assignUser
                    };
                }
                return sub;
            });
        },
        setSubmissionSteps (state, steps) {
            state.submissionSteps = steps;
        },
        setSubmissionTypes (state, types) {
            state.submissionTypes = types;
        },
        setMasterDataOptions (state, options) {
            state.masterDataOptions = options;
        },
        setSubmissionComments (state, types) {
            state.submissionComments = types;
        },
        setSubmissionTasks (state, types) {
            state.submissionTask = types;
        },
        setSubmissionSavedSearches (state, savedSearches) {
            state.submissionSavedSearches = savedSearches;
        },
        updateSubmissionSavedSearches (state, newSavedSearch) {
            const matchedSearchIndex = state.submissionSavedSearches.findIndex(search => search.searchKey === newSavedSearch.searchKey);
            if (state.submissionSavedSearches && state.submissionSavedSearches.length > 0 && matchedSearchIndex !== -1) {
                state.submissionSavedSearches.splice(matchedSearchIndex, 1, newSavedSearch);
            } else {
                state.submissionSavedSearches.push(newSavedSearch);
            }
        },
        deleteSubmissionSavedSearch (state, searchKey) {
            state.submissionSavedSearches = state.submissionSavedSearches.filter(search => search.searchKey !== searchKey);
        },
        sortSavedSearches (state, sortBy) {
            if (sortBy === 'ASCENDING' || sortBy === 'DESCENDING') {
                state.submissionSavedSearches.sort((searchA, searchB) => {
                    const nameA = searchA.name.toLowerCase();
                    const nameB = searchB.name.toLowerCase();
                    if (nameA < nameB) {
                        return sortBy === 'ASCENDING' ? -1 : 1;
                    } else if (nameA > nameB) {
                        return sortBy === 'ASCENDING' ? 1 : -1;
                    }
                    return 0;
                });
            } else if (sortBy === 'OLDEST' || sortBy === 'NEWEST') {
                state.submissionSavedSearches.sort((searchA, searchB) => {
                    const dateA = new Date(searchA.createdDate);
                    const dateB = new Date(searchB.createdDate);
                    if (dateA < dateB) {
                        return sortBy === 'OLDEST' ? -1 : 1;
                    } else if (dateA > dateB) {
                        return sortBy === 'OLDEST' ? 1 : -1;
                    }
                    return 0;
                });
            }
        },
        setSearchedSubmissionId (state, newId) {
            state.searchedSubmissionId = newId;
        },
        setSubmissionStages (state, newStages) {
            state.submissionStages = newStages;
        },
        setSubmissionStatusList (state, newStatusList) {
            state.submissionStatusList = newStatusList;
        },
        setSubmissionCategories (state, newCategories) {
            state.submissionCategories = newCategories;
        },
        setNewLicenseeContact (state, newLicenseeContact) {
            state.newLicenseeContact = newLicenseeContact;
        },
        setSubmissionContractList (state, contractsList) {
            state.submissionContractList = contractsList;
        },
        setUpdatedSubmissionContract (state, updatedContract) {
            state.updatedSubmissionContract = updatedContract;
        },
        setOpenedSubmissions (state, submissionsList) {
            state.openedSubmissions = submissionsList;
        },
        openNewSubmission (state, newSubmission) {
            if (!state.openedSubmissions) {
                state.openedSubmissions = [];
            }
            state.openedSubmissions.push(newSubmission);
        },
        modifyAssigneeInOpenedSubmission (state, updatedSubmission) {
            state.openedSubmissions = state.openedSubmissions.map(submission => submission.submissionId === updatedSubmission.submissionId ? { ...submission, assignUser: updatedSubmission.assignUser } : submission);
        },
        modifyOpenedSubmission (state, updatedSubmission) {
            state.openedSubmissions = state.openedSubmissions.map(submission => submission.submissionId === updatedSubmission.submissionId ? updatedSubmission : submission);
        },
        removeOpenedSubmission (state, submissionId) {
            if (state.openedSubmissions && state.openedSubmissions.length > 0) {
                state.openedSubmissions = state.openedSubmissions.filter(submission => submission.submissionId !== submissionId);
            }
        },
        setNewSubmissionId (state, newSubmissionId) {
            state.newSubmissionId = newSubmissionId;
        },
        setSkuValidation (state, skuValidation) {
            state.skuValidation = skuValidation;
        },
        setSkuMultipleValidation (state, multipleSkuValidation) {
            state.multipleSkuValidation = multipleSkuValidation;
        },
        setNewSavedSearch (state, newSearch) {
            state.newSavedSearch = newSearch;
        },
        setMasterDataSubmissionGameCodes (state, gameCodes) {
            state.newGameCodes = gameCodes;
        },
        setMasterDataLicenseeRequestedStage (state, requestedStage) {
            state.requestedStage = requestedStage;
        },
        setCustomLinesSubmission (state, customLines) {
            state.newCustomLines = customLines;
        },
        setCopySubmission (state, copySubmission) {
            state.newCopySubmission = copySubmission;
        },
        setPackagingSubmissionNumber (state, packagingSubmissions) {
            state.packagingSubmissions = packagingSubmissions;
        }
    },

    actions: {
        async fetchSubmissions ({ commit }, { params }) {
            const { data: { data } } = await fetchSubmissionsService(params);
            commit('setSubmissionsList', data);
        },
        async submissionTasks ({ commit }, { bodyPayload }) {
            const { data: { data } } = await submissionTasksService(bodyPayload);
            commit('setSubmissionTasks', data);
        },
        updateAssigneeInSubmissionsList ({ commit }, { newSubmissions }) {
            commit('changeAssigneeInSubmissionsList', newSubmissions);
        },
        async claimTask ({ commit }, { selectedSubmissions, currentUser }) {
            await claimTaskService(selectedSubmissions);
            selectedSubmissions = selectedSubmissions.map(submission => {
                return {
                    id: submission.submissionId,
                    task: submission.taskId,
                    assignee: currentUser
                };
            });
            commit('updateSubmissionsList', selectedSubmissions);
        },
        async unclaimTask ({ commit }, { selectedSubmissions }) {
            await unclaimTaskService(selectedSubmissions);
            selectedSubmissions = selectedSubmissions.map(submission => {
                return {
                    ...submission,
                    assignee: ''
                };
            });
            commit('updateSubmissionsList', selectedSubmissions);
        },
        async assignTask ({ commit }, { selectedSubmissions, selectedUser }) {
            await assignTasksService(selectedSubmissions);
            selectedSubmissions = selectedSubmissions.map(submission => {
                return {
                    ...submission,
                    assignee: selectedUser
                };
            });
            commit('updateSubmissionsList', selectedSubmissions);
        },
        async reAssignTask ({ commit }, { selectedSubmissions, selectedUser }) {
            await reAssignTasksService(selectedSubmissions);
            selectedSubmissions = selectedSubmissions.map(submission => {
                return {
                    ...submission,
                    assignee: selectedUser
                };
            });
            commit('updateSubmissionsList', selectedSubmissions);
        },
        async fetchSubmissionSteps ({ commit }, { params }) {
            let tmp;
            try { tmp = await fetchSubmissionStepsService(params || {}); } catch (e) {}
            let data;
            if (tmp && tmp.data && tmp.data.data) data = tmp.data.data;
            else {
                data = [
                    LICENSEE_STEP, BAC_REVIEW_STEP, LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP,
                    BAC_SUPERVISOR_STEP, BAC_FINAL_STEP, SAFETY_STEP, SOCIAL_REVIEW_STEP, GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP
                ];
            }
            commit('setSubmissionSteps', data);
        },
        async fetchSubmissionTypes ({ commit }, { params }) {
            if (!params?.type) return;
            const { data: { data } } = await fetchSubmissionTypesService(params || {});
            commit('setSubmissionTypes', data);
        },
        async fetchMasterDataOptions ({ commit }, { params }) {
            if (!params?.type) return;
            const { data: { data } } = await fetchSubmissionTypesService(params || {});
            // commit('setMasterDataOptions', data);
            return data;
        },
        async searchExcelExportSubmissions ({ commit, rootGetters }, { bodyPayload }) {
            const selectedLicensee = rootGetters['auth/getSelectedLicensee'];
            const __userInfo = rootGetters['auth/getUserInfo'] || {};
            const __isReviewer = rootGetters['auth/hasSomeReviewerRole'];
            if (selectedLicensee /* && __userInfo.allowedWithoutLicId != 'Y' */ && !__isReviewer) {
                bodyPayload.licenseeId = selectedLicensee.licenseeId;
            }

            const { data: { data } } = await searchExportExcelSubmissionsService(bodyPayload);
            commit('setExcelExportSubmissionsList', data);
            return data;
        },
        async searchSubmissions ({ commit, rootGetters }, { bodyPayload }) {
            const selectedLicensee = rootGetters['auth/getSelectedLicensee'];
            console.log(`selectedLicensee .. ${JSON.stringify(selectedLicensee)}`);
            const __userProxy = rootGetters['auth/getUserProxyInfo'] || {};
            console.log(`__userProxy .. ${JSON.stringify(__userProxy)}`);
            const __userInfo = rootGetters['auth/getUserInfo'] || {};
            const __isReviewer = rootGetters['auth/hasSomeReviewerRole'];
            const tmpRoles = (__userProxy?.roles || []).filter(r => reviewerRoles?.includes(r));
 
            if ((selectedLicensee /* && __userInfo.allowedWithoutLicId != 'Y' */ && !__isReviewer) || (selectedLicensee?.licenseeName && __userProxy?.username && !tmpRoles?.length > 0)) {
                console.log(`__isReviewer in searchsubmissions ..${JSON.stringify(__isReviewer)}`);
                bodyPayload.licenseeId = selectedLicensee.licenseeId;
            }

            const { data: { data } } = await searchSubmissionsService(bodyPayload);
            commit('setSubmissionsList', data);
        },
        async loadSubmissionComments ({ commit }, { params }) {
            const { data: { data } } = await fetchSubmissionComment(params);
            commit('setSubmissionComments', data);
        },
        async deleteComment ({ commit }, { params }) {
            const { data: { data } } = await deleteSubmissionComment(params);
            return data;
        },
        clearSubmissions ({ commit }) {
            commit('setSubmissionsList', null);
        },
        sortSubmissionsSavedSearches ({ commit, state }, { sortBy }) {
            if (state.submissionSavedSearches && state.submissionSavedSearches.length > 1) {
                commit('sortSavedSearches', sortBy);
            }
        },
        updateSearchedSubmissionId ({ commit }, { searchedSubmissionId }) {
            commit('setSearchedSubmissionId', searchedSubmissionId);
        },
        async fetchSubmissionStages ({ commit }, { params }) {
            const { data: { data } } = await fetchSubmissionStagesService(params || {});
            commit('setSubmissionStages', data);
        },
        async fetchSubmissionStatusList ({ commit }, { params }) {
            const { data: { data } } = await fetchSubmissionStatusService(params || {});
            commit('setSubmissionStatusList', data);
        },
        async fetchSubmissionCategories ({ commit }) {
            const { data: { data } } = await fetchSubmissionCategoriesService();
            commit('setSubmissionCategories', data);
        },
        async updateLicenseeContact ({ commit }, { submissionId, licenseePayload }) {
            const { data: { data } } = await updateLicenseeContactService(submissionId, licenseePayload);
            commit('setNewLicenseeContact', data);
        },
        clearLicenseeContact ({ commit }) {
            commit('setNewLicenseeContact', null);
        },
        async fetchSubmissionContracts ({ commit }, { params }) {
            const { data: { data } } = await fetchDrmreplacementContractService(params);
            commit('setSubmissionContractList', data);
            return data;
        },
        clearSubmissionContractsList ({ commit }) {
            commit('setSubmissionContractList', null);
        },
        async updateSubmissionContract ({ commit }, { submissionId, contractPayload }) {
            const { data: { data } } = await updateSubmissionContractService(submissionId, contractPayload);
            commit('setUpdatedSubmissionContract', data);
        },
        async fetchSubmissionDetails ({ commit, state, rootGetters }, { submissionId, assignUser, submissionStatus, currentStepName }) {
            const doesUserHaveAssociatedLicensees = rootGetters['auth/doesUserHaveAssociatedLicensees'];
            const { data: { data } } = await fetchSubmissionDetailsService(
                submissionId,
                doesUserHaveAssociatedLicensees && submissionStatus !== 'Draft' && currentStepName !== 'Licensee'
                    ? { licensee: 'authorized' } : {}
            );
            commit('openNewSubmission', { ...data, assignUser });
            return data;
        },
        updateAssigneeInOpenedSubmission ({ commit }, { submission }) {
            commit('modifyAssigneeInOpenedSubmission', submission);
        },
        saveOpenedSubmission ({ commit }, { submission }) {
            commit('modifyOpenedSubmission', submission);
        },
        closeAllOpenedSubmissions ({ commit }) {
            commit('setOpenedSubmissions', null);
        },
        async removeOpenedSubmission ({ commit }, { submissionId }) {
            commit('removeOpenedSubmission', submissionId);
        },
        async generateNewSubmissionId ({ commit }, { params }) {
            const { data: { data } } = await generateIdentifierService(params);
            commit('setNewSubmissionId', data);
        },
        async validateSKU ({ commit }, { bodyPayload }) {
            const { data: { data } } = await validateSKUService(bodyPayload);
            commit('setSkuValidation', data);
            return data;
        },
        async validateMultipleSKU ({ commit }, { bodyPayload }) {
            const { data: { data } } = await validateMultipleSKUService(bodyPayload);
            commit('setSkuMultipleValidation', data);
            return data;
        },
        async advanceSearch ({ commit, rootGetters }, { bodyPayload }) {
            const selectedLicensee = rootGetters['auth/getSelectedLicensee'];
            const __userProxy = rootGetters['auth/getUserProxyInfo'] || {};
            const __userInfo = rootGetters['auth/getUserInfo'] || {};
            const __isReviewer = rootGetters['auth/hasSomeReviewerRole'];
            const tmpRoles = (__userProxy?.roles || []).filter(r => reviewerRoles?.includes(r));

            if ((selectedLicensee /* && __userInfo.allowedWithoutLicId != 'Y' */ && !__isReviewer) || (selectedLicensee?.licenseeName && __userProxy?.username && !tmpRoles?.length > 0)) {
                console.log(`__isReviewer ..${JSON.stringify(__isReviewer)}`);
                // Remove the Licensee Name criteria
                bodyPayload.advancedSearch = bodyPayload.advancedSearch.filter(s => s.searchField !== 'licenseeId');
                // Add the filter for users licensee Id
                bodyPayload.advancedSearch.push({
                    searchCondition: 'Equals to',
                    searchField: 'licenseeId',
                    searchString: selectedLicensee.licenseeId
                });
            }

            const { data: { data } } = await advanceSearchSubmissionsService(bodyPayload);
            commit('setSubmissionsList', data);
        },

        async advanceSearchExportExcel ({ commit, rootGetters }, { bodyPayload }) {
            const selectedLicensee = rootGetters['auth/getSelectedLicensee'];
            const __userProxy = rootGetters['auth/getUserProxyInfo'] || {};
            const __userInfo = rootGetters['auth/getUserInfo'] || {};
            const __isReviewer = rootGetters['auth/hasSomeReviewerRole'];
            if ((selectedLicensee /* && __userInfo.allowedWithoutLicId != 'Y' */ && !__isReviewer) || (selectedLicensee && __userProxy) ) {
                // Remove the Licensee Name criteria
                bodyPayload.advancedSearch = bodyPayload.advancedSearch.filter(s => s.searchField !== 'licenseeId');

                // Add the filter for users licensee Id
                bodyPayload.advancedSearch.push({
                    searchCondition: 'Equals to',
                    searchField: 'licenseeId',
                    searchString: selectedLicensee.licenseeId
                });
            }

            const { data: { data } } = await advanceSearchSubmissionsService(bodyPayload);
            commit('setExcelExportSubmissionsList', data);
            return data;
        },
        async fetchSavedSearch ({ commit }, { params }) {
            const { data: { data } } = await fetchSubmissionSavedSearchService(params);
            commit('setSubmissionSavedSearches', data);
        },
        async saveSearch ({ commit }, { bodyPayload }) {
            const { data: { data } } = await createSubmissionSavedSearchService(bodyPayload);
            commit('updateSubmissionSavedSearches', data);
            commit('setNewSavedSearch', data);
        },
        async updateSavedSearch ({ commit }, { bodyPayload, searchKey }) {
            const { data: { data } } = await updateSubmissionSavedSearchService(bodyPayload, searchKey);
            commit('updateSubmissionSavedSearches', data);
        },
        async deleteSavedSearch ({ commit, state }, { searchKey }) {
            await deleteSubmissionSavedSearchService(searchKey);
            if (state.submissionSavedSearches && state.submissionSavedSearches.length > 0) {
                commit('deleteSubmissionSavedSearch', searchKey);
            }
        },
        async fetchMasterDataSubmissionGameCodes ({ commit }, { params }) {
            const { data: { data } } = await masterDataSubmissionGameCodesService(params);
            commit('setMasterDataSubmissionGameCodes', data);
        },
        async masterDataLicenseeRequestedStage ({ commit }, { params }) {
            const { data: { data } } = await masterDataLicenseeRequestedStageService(params);
            commit('setMasterDataLicenseeRequestedStage', data);
        },
        async fetchCustomLinesSubmission ({ commit }) {
            const { data: { data } } = await fetchCustomLinesSubmissionService();
            commit('setCustomLinesSubmission', data);
        },
        async fetchPackagingSubmissionNumber ({ commit }, payload) {
            commit('setPackagingSubmissionNumber', []);
            let { data: { data } } = await fetchPackagingSubmissionNumber(payload);
            if (!data || !data.length) data = ['None'];
            else data = data.filter(e => e).map(e => e.code);
            commit('setPackagingSubmissionNumber', data);
        },
        async createCopyOfSubmission ({ commit }, { bodyPayload }) {
            const { data: { data } } = await createCopyOfSubmissionService(bodyPayload);
            commit('setCopySubmission', data);
            commit('openNewSubmission', data);
        },
        async deleteSubmission ({ commit }, params) {
            const { data: { data } } = await deleteSubmissionService(params);
            console.log('delete submission data: ', data);
            commit('removeOpenedSubmission', params.submissionId);
        },

        async deleteSubmissionSkus ({ commit }, params) {
            const { data: { data } } = await deleteSubmissionSkuService(params);
            console.log('delete submission sku data: ', data);
        },

        async updateSubmission ({ commit }, { bodyPayload }) {
            await updateSubmissionService(bodyPayload);
        },

        async downloadSubmissionReceipt ({ commit }, params) {
            if (!params.submissionIds || !params.submissionIds.length) return;
            return params.submissionIds.map(id => downloadSubmissionReceiptService(id));
        },

        async downloadReviewTemplate ({ commit }, params) {
            if (!params.templateId || !params.templateId.length) return;
            return downloadReviewTemplateService(params.templateId);
        },

        async initAnnualSamples ({ commit }, params) {
            return initAnnualSamplesService(params);
        },

        async manualSKUPush ({ commit }, { bodyPayload }) {
            return await manualSKUPushService(bodyPayload);
        },

        async resendNotification ({ commit }, { bodyPayload }) {
            await resendNotificationService(bodyPayload);
        },

        resetAll ({ commit }) {
            commit('setSubmissionsList', null);
            commit('setSubmissionSteps', null);
            commit('setSubmissionTypes', null);
            commit('setSubmissionSavedSearches', null);
            commit('setSubmissionStages', null);
            commit('setSubmissionStatusList', null);
            commit('setSubmissionCategories', null);
            commit('setOpenedSubmissions', null);
            commit('setSubmissionContractList', null);
            commit('setSubmissionComments', null);
            commit('setSubmissionTasks', null);
        }
    }
};
