import moment from 'moment';
import { useStore } from 'vuex';
import * as XLSX from 'xlsx/xlsx.mjs';
import { reactive, ref, computed } from 'vue';
import useToastNotifications from '@/hooks/toastNotifications.js';

import {
    LICENSEE_STEP, BAC_REVIEW_STEP, LEGAL_REVIEW_STEP, CREATIVE_REVIEW_STEP, SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW,
    SAFETY_STEP, BAC_FINAL_STEP, BAC_SUPERVISOR_STEP, SOCIAL_REVIEW_STEP, GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP, STANDARD_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE, DPA_MATERIALS
} from '@/constants/submissions.js';

import {
    LICENSEE_ROLE, BAC_REVIEWER_ROLE, LEGAL_ROLE, CREATIVE_ROLE,
    SAFETY_ROLE, BAC_ADMIN_ROLE, BAC_SUPERVISOR_ROLE, SOCIAL_REVIEWER_ROLE, DIGITAL_REVIEWER_ROLE, GAMES_REVIEWER_ROLE
} from '@/constants/ba-roles.js';

const { notificationsStack } = useToastNotifications();
const globalSearchSubmissionId = ref('');
const defaultSubmissionFormState = {
    customLines: '',
    submissionId: '',
    licenseeId: null,
    licenseeName: '',
    licenseeAddress: '',
    contactName: '',
    contactPhone: null,
    contactEmail: '',
    additionalEmail: '',
    contractNumber: null,
    submissionType: null,
    shortArticleDescription: '',
    longArticleDescription: '',
    category: '',
    article: null,
    articleNotes: null,
    materialsSupplied: null,
    isFamaCompleted: '',
    isSafetyNeeded: 'N',
    safetyDocIncluded: null,
    rushSubmission: 'N',
    placeSampleHold: 'N',
    placeSafetyHold: 'N',
    currentStage: null,
    currentStep: null,
    status: null,
    nextStage: null,
    nextStep: null,
    legalNextStage: null,
    legalStatus: null,
    legalStatusName: null,
    creativeNextStage: null,
    creativeStatus: null,
    creativeStatusName: null,
    escalateToSupervisor: 'N',
    physicalSampleIncluded: null,
    contractualSampleAmount: null,
    contractualSampleNotes: '',
    deliveryCompany: null,
    trackingNumber: null,
    sampleReceivedDate: null,
    sampleCount: 0,
    returnSample: null,
    returnDeliveryCompany: null,
    returnAccountNumber: null,
    returnName: null,
    returnAddress: null,
    returnPhone: null,
    creationDate: null,
    createdUser: '',
    lastmodifiedDate: null,
    lastmodifiedUser: '',
    currentOwner: '',
    stepStartDate: null,
    stageStartDate: null,
    gameCode: null,
    assetType: null,
    styleGuideName: '',
    hasbroContractNo: '',
    hasbroProjectNo: '',
    hasbroAssortmentNo: '',
    packingReferenceNo: null,
    currentAction: null,
    styleGuideId: null,
    pressReleaseCategory: '',
    collaborationCount: 0,
    iteration: null,
    articleName: null,
    licenseeNotificationDate: null,
    supervisorNextStage: null,
    previousStage: null,
    previousStep: null,
    assignDate: null,
    articleRightDefId: null,
    assignToRole: null,
    assignToUser: null,
    legacySubmissionId: null,
    opaId: null,
    preProdSampleCount: 0,
    prodSampleCount: 0,
    sendToFinal: 'N',
    isSafetyCompleted: 'N',
    sendToLegalCreative: null,
    licenseeReqStage: null,
    podArtReferenceNumber: null,
    genLob: null,
    genCategory: null,
    genType: null,
    onShelfDate: null,
    finalInventory: '',
    submissionTypeName: '',
    submissionStatus: '',
    skipFinal: 'N',
    characters: [],
    contractStatus: '',
    assets: [],
    skus: [],
    comments: [],
    currentStageName: null,
    currentStepName: null,
    legalNextStageName: null,
    creativeNextStageName: null,
    supervisorNextStageName: null,
    currentActionName: null,
    assignUser: '',
    routebackStep: '',

    currentSampleCount: null,
    totalSampleCount: 0,
    licenseeComment: '',
    internalNote: '',
    isAdminEdit: false,
    gameCodeChecked: 'N',

    attachmentsToBeDeleted: [],
    attachmentsToBeUpdated: [],

    uxGenCharacterComments: null,

    // social review model
    socCopyrightCheck: '',
    socCharCheck: '',
    socTemplateCheck: '',
    socCaptionCheck: '',
    socLogoCheck: '',
    socSpellCheck: '',
    socApprovalDate: null,
    socPostedEng: '',
    socPostedPhoto: '',
    socReviewInfluencers: '',
    socEvergreenContent: '',
    socContentCheck: '',
    dpaIpCheck: '',
    dpaPromotionCheck: '',
    dpaLegalCheck: '',
    dpaLogoCheck: '',
    dpaTitleCheck: '',
    dpaSpellCheck: ''
};
let submissionForm = reactive({
    ...defaultSubmissionFormState
});

const defaultSubmisionFormValidationsState = {
    shortArticleDescription: '',
    longArticleDescription: '',
    category: '',
    article: '',
    materialsSupplied: '',
    safetyDocIncluded: '',
    physicalSampleIncluded: '',
    licenseeReqStage: '',
    onShelfDate: '',
    finalInventory: '',
    deliveryCompany: '',
    totalSampleCount: '',
    trackingNumber: '',
    currentSampleCount: '',
    returnSample: '',
    contractNumber: '',
    genCategory: '',
    genLob: '',
    genType: '',
    gameCode: '',
    assetType: '',
    pressReleaseCategory: '',
    opaId: '',
    returnDeliveryCompany: '',
    returnAccountNumber: '',
    returnName: '',
    returnAddress: '',
    returnPhone: '',
    podArtReferenceNumber: '',
    styleGuideName: '',
    skuCharacters: '',
    upcOptOutComments: '',
    upcOptOutApproval: '',
    upcGlobalValidation: '',
    licenseeComment: '',
    internalNote: '',
    legalStatus: '',
    creativeStatus: '',
    creativeNextStage: '',
    characters: [],
    skus: [],
    skuTable: '',
    reUseSku: '',
    skuUpcTable: '',
    legalNextStage: '',
    currentAction: '',
    collaborations: '',
    uxGenCharacterComments: '',
    currentStage: '',

    // social review model
    socCopyrightCheck: '',
    socCharCheck: '',
    socTemplateCheck: '',
    socCaptionCheck: '',
    socLogoCheck: '',
    socSpellCheck: '',
    socApprovalDate: '',
    socPostedEng: '',
    socPostedPhoto: '',
    socReviewInfluencers: '',
    socEvergreenContent: '',
    socContentCheck: '',
    dpaIpCheck: '',
    dpaPromotionCheck: '',
    dpaLegalCheck: '',
    dpaLogoCheck: '',
    dpaTitleCheck: '',
    dpaSpellCheck: ''
};

const submissionFilters = reactive({
    submissionTypeName: 'All Submissions',
    currentStep: 'Personal Tasks',
    status: 'Active',
    searchScope: [],
    columnChooser: [],
    searchKeyword: ''
});

let submissionFormValidations = reactive({
    ...defaultSubmisionFormValidationsState
});

const isSubmissionFormValid = () => {
    // check UPC OPT out for all the SKUs
    if (submissionForm && submissionForm.skus && submissionForm.skus.length > 0) {
        let invalidSkus = submissionForm.skus.filter(s => s.upcOptOut === 'Y' && s.upcOptOutApproval !== 'Y');
        if (submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW') invalidSkus = [];

        if ([STANDARD_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
            invalidSkus = [];
        }

        invalidSkus.map(s => {
            notificationsStack.value.push({
                type: 'error',
                message: `UPC Opt Out Approval is required for SKU: ${s.skuNo}`
            });
        });
        if (invalidSkus.length) return false;
    }

    // Collaborations
    if (submissionFormValidations.collaborations) {
        notificationsStack.value.push({
            type: 'error',
            message: submissionFormValidations.collaborations
        });
        return false;
    }

    // Check Deleted Files
    if (submissionForm.attachmentsToBeDeleted && submissionForm.attachmentsToBeDeleted.length && process.env.VUE_APP_TITLE !== 'ASGARD (Dev)') {
        {
            const __tmp = (submissionForm.assets || []).filter(a =>
                a.assetType === 'SUBMISSION' && !submissionForm.attachmentsToBeDeleted.includes(a.assetId)
            );
            if (!__tmp || !__tmp.length) {
                notificationsStack.value.push({
                    type: 'error',
                    message: 'The submission needs a valid attachment.'
                });
                return false;
            }
        }
        if (((submissionForm.isSafetyNeeded === 'Y' || ['Yes', 'Y'].includes(submissionForm.safetyDocIncluded)) ||
                (['NEW', 'EDIT'].includes(submissionFormMode.value) && ['Yes', 'Y'].includes(submissionForm.safetyDocIncluded))) &&
                    submissionForm.currentStepName === LICENSEE_STEP) {
            const __tmp = (submissionForm.assets || []).filter(a =>
                a.assetType === 'SAFETY' && !submissionForm.attachmentsToBeDeleted.includes(a.assetId)
            );
            if (!__tmp || !__tmp.length) {
                notificationsStack.value.push({
                    type: 'error',
                    message: 'The submission needs a valid safety document.'
                });
                return false;
            }
        }
    }

    // Check for String and Arrays props
    const strProps = Object.values(submissionFormValidations).filter(v => typeof v === 'string') || [];
    const arrayProps = Object.values(submissionFormValidations).filter(v => Array.isArray(v)) || [];
    const arrayPropsCheck = arrayProps.map(arr => arr.some(e => e));

    // console.log(`isSubmissionFormValid : strProps .. ${strProps}`)
    // console.log(`isSubmissionFormValid : arrayPropsCheck .. ${arrayPropsCheck}`)

    return strProps.filter(e => e).length < 1 && arrayPropsCheck.filter(e => e).length < 1;
};

const submissionFormMode = ref('NEW');
// const isSubmissionFormSubmitTriggered = ref(false);

const notifiersEnabled = ref(true);

export default () => {
    const store = useStore();

    const updateNotifierStatus = (isEnabled) => {
        // console.log(`>>> Setting NOTIFIER status to ENABLED = ${isEnabled}`);
        notifiersEnabled.value = isEnabled;
    };

    const updateSubmissionForm = (updatedForm, transformFormData) => {
        // console.log(`>>> called updateSubmissionForm`)
        // console.log(updatedForm)
        submissionForm = Object.assign(submissionForm, updatedForm);
        if (transformFormData) transformSubmissionFormData();
    };

    const resetSubmissionForm = () => {
        Object.keys(submissionForm).forEach(key => {
            if (!(key in defaultSubmissionFormState)) {
                delete submissionForm[key];
            } else {
                submissionForm[key] = defaultSubmissionFormState[key];
            }
        });

        // Handle Assets separately .. the Reactive object is storing the assets on the default object
        submissionForm.assets = [];
        defaultSubmissionFormState.assets = [];
    };

    const setTotalSampleCount = () => {
        if (submissionForm.currentStageName === 'Pre-Production') {
            submissionForm.totalSampleCount = submissionForm.preProdSampleCount;
        } else if (submissionForm.currentStageName === 'Production') {
            submissionForm.totalSampleCount = submissionForm.prodSampleCount;
        } else {
            submissionForm.totalSampleCount = submissionForm.sampleCount;
        }
    };

    /**
     * transforms submission form data as expected by submission form component
     */
    const transformSubmissionFormData = () => {
        setTotalSampleCount();
        submissionForm.finalInventory = transformSubmissionFlag(submissionForm.finalInventory);
        submissionForm.physicalSampleIncluded = transformSubmissionFlag(submissionForm.physicalSampleIncluded);
        submissionForm.safetyDocIncluded = transformSubmissionFlag(submissionForm.safetyDocIncluded);
        submissionForm.returnSample = transformSubmissionFlag(submissionForm.returnSample);
        submissionForm.currentSampleCount = null;

        // Set the general comments
        if (submissionForm.genCharacterEntry && submissionForm.genCharacterEntry.genCharacterComments) {
            submissionForm.uxGenCharacterComments = submissionForm.genCharacterEntry.genCharacterComments;
        }

        // social dropdown values to send as per payload.
        if (([SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW].includes(submissionForm.materialsSupplied) && submissionForm.social) || DPA_MATERIALS.includes(submissionForm.materialsSupplied)) {
            submissionForm.socCopyrightCheck = submissionForm.social.socCopyrightCheck;
            submissionForm.socCharCheck = submissionForm.social.socCharCheck;
            submissionForm.socCaptionCheck = submissionForm.social.socCaptionCheck;
            submissionForm.socLogoCheck = submissionForm.social.socLogoCheck;
            submissionForm.socSpellCheck = submissionForm.social.socSpellCheck;
            submissionForm.socApprovalDate = submissionForm.social.socApprovalDate;
            submissionForm.socEvergreenContent = submissionForm.social.socEvergreenContent;
            if (DPA_MATERIALS.includes(submissionForm.materialsSupplied)) {
                submissionForm.dpaIpCheck = submissionForm.social.dpaIpCheck;
                submissionForm.dpaLegalCheck = submissionForm.social.dpaLegalCheck;
                submissionForm.dpaPromotionCheck = submissionForm.social.dpaPromotionCheck;
                submissionForm.dpaLogoCheck = submissionForm.social.dpaLogoCheck;
                submissionForm.dpaTitleCheck = submissionForm.social.dpaTitleCheck;
                submissionForm.dpaSpellCheck = submissionForm.social.dpaSpellCheck;
            }
            // dropdowns
            if (submissionForm.materialsSupplied === SOCIAL_MEDIA_POST_METARIAL) {
                submissionForm.socPostedEng = transformSubmissionFlag(submissionForm.social.socPostedEng);
                submissionForm.socPostedPhoto = transformSubmissionFlag(submissionForm.social.socPostedPhoto);
                submissionForm.socReviewInfluencers = transformSubmissionFlag(submissionForm.social.socReviewInfluencers);
            } else if (submissionForm.materialsSupplied === SOCIAL_DIGITAL_VIDEO_REVIEW) {
                submissionForm.socContentCheck = submissionForm.social.socContentCheck;
                submissionForm.socTemplateCheck = submissionForm.social.socTemplateCheck;
            }
        }
    };

    /**
     * transforms submission flags (fields with Y/N value) as expected by submission form component
     */
    const transformSubmissionFlag = flag => ['Y', 'Yes'].includes(flag) ? 'Yes' : 'No';

    /**
     * returns a new object of submission form data as expected by server - use it before sending payload to server
     * @returns {Object}
     */
    const getSubmissionFormDataAsExpectedByServer = (isSubmitting) => {
        let {
            currentSampleCount, totalSampleCount, licenseeComment, internalNote, assignUser, isAdminEdit,
            ...restSubmissionForm
        } = submissionForm; // removing extra fields from submission form

        restSubmissionForm.physicalSampleIncluded = revertSubmissionFlag(restSubmissionForm.physicalSampleIncluded);
        restSubmissionForm.safetyDocIncluded = revertSubmissionFlag(restSubmissionForm.safetyDocIncluded);
        restSubmissionForm.returnSample = revertSubmissionFlag(restSubmissionForm.returnSample);
        restSubmissionForm.finalInventory = revertSubmissionFlag(restSubmissionForm.finalInventory);

        if ([SOCIAL_MEDIA_POST_METARIAL, SOCIAL_DIGITAL_VIDEO_REVIEW].includes(submissionForm.materialsSupplied) || DPA_MATERIALS.includes(submissionForm.materialsSupplied)) {
            restSubmissionForm.social = {};
            restSubmissionForm.social.socCopyrightCheck = submissionForm.socCopyrightCheck;
            restSubmissionForm.social.socCharCheck = submissionForm.socCharCheck;
            restSubmissionForm.social.socTemplateCheck = submissionForm.socTemplateCheck;
            restSubmissionForm.social.socCaptionCheck = submissionForm.socCaptionCheck;
            restSubmissionForm.social.socLogoCheck = submissionForm.socLogoCheck;
            restSubmissionForm.social.socSpellCheck = submissionForm.socSpellCheck;
            restSubmissionForm.social.socApprovalDate = submissionForm.socApprovalDate;
            restSubmissionForm.social.socEvergreenContent = submissionForm.socEvergreenContent;
            if (DPA_MATERIALS.includes(submissionForm.materialsSupplied)) {
                restSubmissionForm.social.dpaIpCheck = submissionForm.dpaIpCheck;
                restSubmissionForm.social.dpaLegalCheck = submissionForm.dpaLegalCheck;
                restSubmissionForm.social.dpaPromotionCheck = submissionForm.dpaPromotionCheck;
                restSubmissionForm.social.dpaLogoCheck = submissionForm.dpaLogoCheck;
                restSubmissionForm.social.dpaTitleCheck = submissionForm.dpaTitleCheck;
                restSubmissionForm.social.dpaSpellCheck = submissionForm.dpaSpellCheck;
            }
            // dropdowns
            if (!DPA_MATERIALS.includes(submissionForm.materialsSupplied)) {
                if (submissionForm.materialsSupplied === SOCIAL_MEDIA_POST_METARIAL) {
                    restSubmissionForm.social.socPostedEng = revertSubmissionFlag(submissionForm.socPostedEng);
                    restSubmissionForm.social.socPostedPhoto = revertSubmissionFlag(submissionForm.socPostedPhoto);
                    restSubmissionForm.social.socReviewInfluencers = revertSubmissionFlag(submissionForm.socReviewInfluencers);
                } else if (submissionForm.materialsSupplied === SOCIAL_DIGITAL_VIDEO_REVIEW) {
                    restSubmissionForm.social.socContentCheck = submissionForm.socContentCheck;
                }
            }

        }

        // saving total sample based on current stage
        if (restSubmissionForm.currentStageName === 'Pre-Production') {
            restSubmissionForm.preProdSampleCount = totalSampleCount;
            if (!restSubmissionForm.preProdSampleCount) restSubmissionForm.preProdSampleCount = 0;
        } else if (restSubmissionForm.currentStageName === 'Production') {
            restSubmissionForm.prodSampleCount = totalSampleCount;
            if (!restSubmissionForm.prodSampleCount) restSubmissionForm.prodSampleCount = 0;
        } else {
            restSubmissionForm.sampleCount = totalSampleCount;
            if (!restSubmissionForm.sampleCount) restSubmissionForm.sampleCount = 0;
        }

        restSubmissionForm.characters = restSubmissionForm.characters
            .filter(char => char.characterName && char.characterId)
            .map(char => {
                delete char.characterTableData;

                const { selected, ...restChar } = char;
                if (typeof restChar.isActorLikenessPackaging === 'boolean') restChar.isActorLikenessPackaging = restChar.isActorLikenessPackaging === true ? 'Y' : '';
                if (typeof restChar.isActorLikenessProduct === 'boolean') restChar.isActorLikenessProduct = restChar.isActorLikenessProduct === true ? 'Y' : '';
                if (typeof restChar.isActorLikenessVoice === 'boolean') restChar.isActorLikenessVoice = restChar.isActorLikenessVoice === true ? 'Y' : '';
                return restChar;
            });

        if (restSubmissionForm.uxGenCharacterComments) {
            restSubmissionForm.genCharacterEntry = { genCharacterComments: restSubmissionForm.uxGenCharacterComments };
            delete restSubmissionForm.uxGenCharacterComments;
        }
        const hasGeneralCharacters = (restSubmissionForm.characters || []).some(c => c.characterName?.toLowerCase().includes('(general)'));
        if (!hasGeneralCharacters) delete restSubmissionForm.genCharacterEntry;

        const licenseeCanHaveMultipleSkusPerSubmission = (store.getters['baSubmissions/getMultipleSkuValidation'] || {}).multipleSkusAllowed;
        restSubmissionForm.skus = restSubmissionForm.skus.map(sku => {
            delete sku.characterTableData;
            delete sku.upcTableData;

            // Copy the SG Cahracters to SKU .. overwrite if the SKU Character DOES NOT have the key
            if (!licenseeCanHaveMultipleSkusPerSubmission) {
                // Map each of the SG Characters to SKU Characters
                sku.characters = [...(restSubmissionForm.characters || [])].map(char => {
                    const retChar = { ...char };

                    // Clean the SG Character Keys and Ids
                    // eslint-disable-next-line no-lone-blocks
                    {
                        delete retChar.submissionCharacterKey;
                        delete retChar.submissionKey;
                        delete retChar.submissionId;
                        delete retChar.styleGuideId;
                    }

                    // Add the SKU Character Keys if already assigned
                    const currentChar = sku.characters.find(c => char.characterId && `${c.characterId}` === `${char.characterId}`);
                    if (currentChar) {
                        if (currentChar.submissionSkuCharacterKey) retChar.submissionSkuCharacterKey = currentChar.submissionSkuCharacterKey;
                        if (currentChar.submissionSkuKey) retChar.submissionSkuKey = currentChar.submissionSkuKey;
                    }
                    return retChar;
                });
            }

            if (sku.characters && sku.characters.length) {
                sku.characters = sku.characters.map(char => {
                    const { selected, ...restChar } = char;
                    if (typeof restChar.isActorLikenessPackaging === 'boolean') restChar.isActorLikenessPackaging = restChar.isActorLikenessPackaging === true ? 'Y' : '';
                    if (typeof restChar.isActorLikenessProduct === 'boolean') restChar.isActorLikenessProduct = restChar.isActorLikenessProduct === true ? 'Y' : '';
                    if (typeof restChar.isActorLikenessVoice === 'boolean') restChar.isActorLikenessVoice = restChar.isActorLikenessVoice === true ? 'Y' : '';
                    return restChar;
                });
            }

            if (sku.upcs && sku.upcs.length) {
                sku.upcs = sku.upcs.map(upc => {
                    const { selected, ...restupc } = upc;
                    return restupc;
                });
            }
            return sku;
        });

        // add new comments (if any)
        if (internalNote) {
            restSubmissionForm.comments.push(getNewComment(internalNote, 1, isSubmitting));
        }
        if (licenseeComment) {
            restSubmissionForm.comments.push(getNewComment(licenseeComment, 2, isSubmitting));
        }

        // update submission routing name fields
        restSubmissionForm = {
            ...restSubmissionForm,
            currentStepName: (submissionSteps.value.find(option => option.stepKey === submissionForm.currentStep) || { stepName: null }).stepName,
            currentStageName: (submissionStages.value.find(option => option.stageKey === submissionForm.currentStage) || { stageName: null }).stageName,
            legalNextStageName: (submissionStages.value.find(option => option.stageKey === submissionForm.legalNextStage) || { stageName: null }).stageName,
            creativeNextStageName: (submissionStages.value.find(option => option.stageKey === submissionForm.creativeNextStage) || { stageName: null }).stageName,
            supervisorNextStageName: (submissionStages.value.find(option => option.stageKey === submissionForm.supervisorNextStage) || { stageName: null }).stageName
        };
        return restSubmissionForm;
    };
    const revertSubmissionFlag = flag => ['Y', 'Yes'].includes(flag) ? 'Y' : 'N';

    /**
     * returns comment object as expected by server
     * @param {String} comment
     * @param {Number} refObjTypeKey
     */
    const getNewComment = (comment, refObjTypeKey, isSubmitting) => {
        // set submissionStatusKey
        let submissionStatusKey;
        {
            let licStatusKey = 'Draft';
            if (isSubmitting) licStatusKey = 'Submitted';

            const tmp = submissionStatusList.value.find(s =>
                s.statusName === (submissionForm.submissionStatusKeyName || licStatusKey) &&
                s.submissionType === submissionForm.submissionType
            );

            if (tmp) submissionStatusKey = tmp.statusKey;
            else submissionStatusKey = 8; // Draft for Standard submission
        }
        return {
            description: comment,
            creatUserName: userInfo.value.name,
            submissionId: submissionForm.submissionId,
            refObjTypeKey: refObjTypeKey,
            submissionStageKey: submissionForm.currentStage ? submissionForm.currentStage : 1,
            submissionStepKey: submissionForm.currentStep ? submissionForm.currentStep : 1,
            submissionStatusKey,
            taskId: submissionForm.taskId
        };
    };

    // submission form validations
    const resetSubmissionFormValidations = () => {
        submissionFormValidations = Object.assign(submissionFormValidations, defaultSubmisionFormValidationsState);
    };

    // submission form routing
    const submissionStages = computed(() => store.getters['baSubmissions/getSubmissionStages'] || []);
    const submissionSteps = computed(() => store.getters['baSubmissions/getSubmissionSteps'] || []);
    const submissionStatusList = computed(() => store.getters['baSubmissions/getSubmissionStatusList'] || []);

    const submissionAssignToUserList = computed(() => store.getters['users/getUsersList']);
    const userInfo = computed(() => store.getters['auth/getUserInfo']);

    // user preferences calls
    const fetchedUserPreference = computed(() => store.getters['users/getUserPreferenceList']);
    const fetchUserPreferences = async () => {
        try {
            await store.dispatch('users/fetchUserPreferences');
        } catch (err) {
            console.error(err);
        }
    };

    const userRoles = computed(() => store.getters['auth/getUserRoles']);
    const hasSubmissionAccess = (stepName) => {
        let retValue = false;
        switch (stepName) {
        case LICENSEE_STEP: retValue = userRoles.value.includes(LICENSEE_ROLE); break;
        case BAC_REVIEW_STEP: retValue = userRoles.value.includes(BAC_REVIEWER_ROLE); break;
        case LEGAL_REVIEW_STEP: retValue = userRoles.value.includes(LEGAL_ROLE); break;
        case CREATIVE_REVIEW_STEP: retValue = userRoles.value.includes(CREATIVE_ROLE); break;
        case SAFETY_STEP: retValue = userRoles.value.includes(SAFETY_ROLE); break;
        case BAC_FINAL_STEP: retValue = userRoles.value.includes(BAC_ADMIN_ROLE); break;
        case BAC_SUPERVISOR_STEP: retValue = userRoles.value.includes(BAC_SUPERVISOR_ROLE); break;
        case SOCIAL_REVIEW_STEP: retValue = userRoles.value.includes(SOCIAL_REVIEWER_ROLE); break;
        case DIGITAL_REVIEW_STEP: retValue = userRoles.value.includes(DIGITAL_REVIEWER_ROLE); break;
        case GAMES_REVIEW_STEP: retValue = userRoles.value.includes(GAMES_REVIEWER_ROLE); break;
        }
        return retValue;
    };

    /* reusable excel export functions custom logic */
    const generateExcel = (fileNameFrom, xlsxColumns, xlsxData) => {
        const createXLSLFormatObj = [];
        const newXlsHeader = [];

        if (xlsxColumns.value.length === 0) {
            console.log('Add columns!');
            return;
        }
        if (xlsxData.value.length === 0) {
            console.log('Add data!');
            return;
        }
        xlsxColumns.value.map(column => {
            newXlsHeader.push(column.label);
        });

        createXLSLFormatObj.push(newXlsHeader);
        xlsxData.value.map(value => {
            const innerRowData = [];
            xlsxColumns.value.map(val => {
                let fieldValue = value[val.field];
                if (val.field.split('.').length > 1) {
                    fieldValue = getNestedValue(value, val.field);
                }
                if (val.dataFormat && typeof val.dataFormat === 'function') {
                    innerRowData.push(val.dataFormat(fieldValue));
                } else {
                    innerRowData.push(fieldValue);
                }
            });
            createXLSLFormatObj.push(innerRowData);
        });

        const fileName = `${fileNameFrom + moment().format('MMDDYYYY-HHMM')}` + '.' + 'xls';
        const sheetName = 'submission';

        const wb = XLSX?.utils?.book_new();
        const ws = XLSX?.utils?.aoa_to_sheet(createXLSLFormatObj);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName);
    };

    const getNestedValue = (object, string) => {
        string = string.replace(/\[(\w+)\]/g, '.$1');
        string = string.replace(/^\./, '');
        const a = string.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i];
            if (k in object) {
                object = object[k];
            } else {
                return;
            }
        }
        return object;
    };
    /* reusable excel export functions custom logic */

    return {
        submissionForm,
        globalSearchSubmissionId,
        notifiersEnabled,
        updateSubmissionForm,
        updateNotifierStatus,
        resetSubmissionForm,
        submissionFormMode,
        transformSubmissionFormData,
        getSubmissionFormDataAsExpectedByServer,
        // submission form validations
        submissionFormValidations,
        isSubmissionFormValid,
        resetSubmissionFormValidations,
        // submission form routing
        submissionStages,
        submissionSteps,
        submissionStatusList,
        submissionAssignToUserList,
        fetchedUserPreference,
        fetchUserPreferences,
        submissionFilters,
        hasSubmissionAccess,
        generateExcel
    };
};
