import { createApp } from 'vue';
import App from './App.vue';
import plugins from '@/plugins/plugins.js';
import '@/assets/styles/global.css';
import router from '@/router.js';
import store from '@/store/store.js';
import VueGridLayout from 'vue-grid-layout';
import BaPlugin from '@/constants/plugin.js';

createApp(App)
    .use(router)
    .use(store)
    .use(plugins)
    .use(VueGridLayout)
    .use(BaPlugin)
    .mount('#app');
